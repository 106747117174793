import * as React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const Container = styled.div`
    ${tw`relative bg-neutral-800 px-8 text-gray-300 `}
`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto px-2 py-16 lg:py-20  flex flex-wrap flex-col  md:flex-row justify-start`;
const Column = tw.div`md:w-1/5 mr-11 mb-16 lg:mr-0 md:mb-0`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const WideColumn = tw(Column)`text-center md:text-left w-full  pb-10 m-0`;
const ColumnHeading = tw.h6`font-bold`;
const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-red-800 hocus:border-red-800 pb-1 transition duration-300`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoText = tw.h6`font-black text-gray-200`;
const CompanyDescription = tw.p`mt-4 max-w-md font-medium text-sm mx-auto md:mx-0 md:mr-4 `;
const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const Footer = () => {
    return (
        <Container>
            <Content>
                <FiveColumns>
                    <Column>
                        <ColumnHeading>About us</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link href="/about">회사 소개</Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>서비스</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link href="/dt">
                                    디지털 트렌스포메이션
                                </Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="/ICT">
                                    ICT융합기술R&D / 기술사업화
                                </Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="/service-incubation">
                                    서비스 인큐베이팅
                                </Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>채용</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link href="/recruit">팀 문화</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="/recruit/jobs">채용공고</Link>{" "}
                                {/* <span className="ml-2 rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-semibold text-green-800 dark:bg-green-200 dark:text-green-900">
                                    채용중
                                </span> */}
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>고객센터</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link href="/contact-intro">문의하기</Link>
                            </LinkListItem>                          
                            <LinkListItem>
                                <Link href="/policy">개인정보처리방침</Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                </FiveColumns>

                <Divider />
                <WideColumn>
                    <LogoContainer>
                        <LogoText>케이유융합소프트웨어연구센터<br/>KUSRC</LogoText>
                    </LogoContainer>
                    <CompanyDescription>
                    서울시 성북구 안암동5가 고려대학교 자연계캠퍼스 내
                    <br/>
                     Copyright © 2022 KU Software Research Center. All Rights Reserved.
                    </CompanyDescription>
                </WideColumn>
            </Content>
        </Container>
    );
};

export default Footer;
