import React from "react";
import Footer from "../components/Footer";
import RecrutiConHead from "../components/RecruitConHead";
import RecrutiCon from "../components/RecruitCon";
import Cta from "../components/Cta";
import StackedFeature from "../components/Features/StackedFeature";
import SimpleTags from "../components/SimpleTags";

import Hero from "../components/Heros/HeroRecruit";

const RecruitPage = () => (
    <>
        <Hero />
        <RecrutiConHead />
        <RecrutiCon />
        <SimpleTags />
        <Cta
            title={"KUSRC에 \n 합류하세요."}
            subtitle={
                "케이유융합소프트웨어가 만드는 수많은 혁신의 순간들, 당신과 함께 만들고 싶습니다. 지금, KUSRC 에 합류하세요."
            }
            href="/recruit/jobs"
            button="지원하기"
            imageSrc="/img/team-overview.jpg"
        />
        <Footer />
    </>
);

export default RecruitPage;
