import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useRef } from "react";
import {
    motion,
    useMotionValue,
    useTransform,
    useAnimationFrame
} from "framer-motion";
import { wrap } from "@motionone/utils";
import Tete from "./Hello";
import Tete2 from "./Hellomobile";

interface ParallaxProps {
    children: any;
    baseVelocity: number;
}
function ParallaxText({ children, baseVelocity = 100 }: ParallaxProps) {
    const baseX = useMotionValue(0);
    const x = useTransform(baseX, v => ` ${wrap(-v, 0, v)}px`);
    // const x = useTransform(baseX, v => `translateX:${v}%`);
    const directionFactor = useRef<number>(1);

    useAnimationFrame((t, delta) => {
        const moveBy = directionFactor.current * baseVelocity * (delta / 1000);

        baseX.set(baseX.get() + moveBy);
    });

    return (
        <div className="parallax w-full">
            <motion.div
                className="scroller flex flex-row items-center justify-center gap-x-8 xl:gap-x-12"
                style={{ x }}
                transition={{ yoyo: "Infinity" }}
            >
                {children}
            </motion.div>
        </div>
    );
}

const ScrollVelocity = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    };
    useEffect(() => {
        window.addEventListener("scroll", updateScroll);
    });

    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        <div className="mb-48 w-full overflow-hidden">
            <div className="mx-auto max-w-screen-lg px-10 ">
                <div className="mt-36 lg:mt-44">
                    <p className="text-3xl  font-extrabold text-slate-900">
                        Reference
                    </p>
                    <p className="font-display mb-14 text-base text-slate-900">
                        케이유융합소프트웨어연구센터(KUSRC)와 함께합니다.
                    </p>
                </div>
            </div>

            {windowSize.innerWidth >= 768 ? (
                <>
                    <Tete />
                </>
            ) : (
                <>
                    <Tete2 />
                </>
            )}
        </div>
    );
};

export default ScrollVelocity;
function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}
