import React from "react";
import Footer from "../components/Footer";
import Hero from "../components/Heros/HeroContact";

const ContactIntro = () => (
    <>
        <Hero />
        <section className="py-6 dark:bg-gray-800 dark:text-gray-100 sm:py-12">
            <div className="container mx-auto space-y-8 p-6">
                <div className="space-y-2 text-center">
                    <h2 className="text-3xl font-bold md:text-4xl">
                        Contact US
                    </h2>
                </div>
                <div className="max-w-2xl mx-auto grid grid-cols-1 gap-x-10 md:grid-cols-2 justify-items-center">
                    <a className="w-full max-w-md text-center" href="/recruit/jobs">
                        <img
                            className="mx-auto h-52 w-full rounded-lg object-cover object-center"
                            src="/img/recruit_img_01.jpg"
                        />
                        <div className="mt-2">
                            <h3 className="text-lg font-medium text-gray-700 dark:text-gray-200">
                                채용지원
                            </h3>
                        </div>
                    </a>
                    <a className="w-full max-w-md text-center" href="/contact">
                        <img
                            className="mx-auto h-52 w-full rounded-lg object-cover object-center"
                            src="/img/contact-us-03.jpg"
                        />
                        <div className="mt-2">
                            <h3 className="text-lg font-medium text-gray-700 dark:text-gray-200">
                                사업문의
                            </h3>
                        </div>
                    </a>
                </div>
            </div>
        </section>
        <Footer />
    </>
);

export default ContactIntro;
