import React from "react";
import { motion, Variants } from "framer-motion";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import { Container } from "../layouts/Common";
const cardVariants: Variants = {
    offscreen: {
        y: -50
    },
    onscreen: {
        y: 0,
        transition: {
            type: "spring",
            bounce: 0,
            duration: 0.8
        }
    }
};

const PrevArrow = ({ onClick = "() => slider?.current?.slickPrev()" }: any) => {
    return (
        <button
            onClick={onClick}
            className="rounded-full border p-2 text-gray-800 transition-colors duration-300 hover:bg-gray-100 rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                />
            </svg>
        </button>
    );
};
const NextArrow = ({ onClick = "() => slider?.current?.slickNext()" }: any) => {
    return (
        <button
            onClick={onClick}
            className="rounded-full border p-2 text-gray-800 transition-colors duration-300 hover:bg-gray-100 rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 lg:mx-6"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                />
            </svg>
        </button>
    );
};

const callouts = [
    {
        name: "근무환경",
        description:
            "9시 ~ 11시 자유로운 출근\n  최신 Mac & 듀얼모니터 \n 식대 제공\n  카페테리아 \n  야외 테라스",
        imageSrc: "/img/recruit_img_03.jpg"
    },
    {
        name: "교육",
        description:
            "업무 관련 교육비 지원 \n 자격증 취득 지원 \n논문 무제한 열람비 지원  \n자격증 취득 시 인센티브 지급  \n신규입사자 교육 프로그램",
        imageSrc: "/img/recruit_img_07.jpg",
        imageAlt: "."
    },
    {
        name: "문화",
        description:
            "생일 및 결혼기념일 지원 \n 무제한 스낵바 \n  월 1회 회식비 지원\n   리조트 회원권 이용 제공\n   청년내일채움공제 지원",
        imageSrc: "/img/recruit_img_10.jpg",
        imageAlt: ""
    },
    {
        name: "휴가",
        description: "자유로운 연차 사용  \n 근로자의 날 휴무 \n ",
        imageSrc: "/img/recruit_img_08.jpg",
        imageAlt: ""
    },
    {
        name: "근무환경",
        description:
            "9시 ~ 11시 자유로운 출근\n  최신 Mac & 듀얼모니터 \n 식대 제공\n  카페테리아 \n  야외 테라스",
        imageSrc: "/img/recruit_img_03.jpg"
    }
];

const RecrutiCon = () => {
    const slider = React.useRef<Slider>(null);
    const settings = {
        dots: false,
        autoplay: false,
        Speed: 5000,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <>
            <Container>
                <motion.div
                    className="mx-auto max-w-2xl  py-16 sm:py-24 md:max-w-3xl lg:max-w-none lg:py-32"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                >
                    <h2 className="flex justify-between">
                        <span className="title-font mb-2 text-3xl font-bold text-gray-700 dark:text-white md:text-4xl">
                            {" "}
                            KUSRC 복지
                        </span>

                        <div className="flex w-fit items-center justify-between ">
                            <PrevArrow
                                onClick={() => slider?.current?.slickPrev()}
                            />

                            <NextArrow
                                onClick={() => slider?.current?.slickNext()}
                            />
                        </div>
                    </h2>

                    <Slider ref={slider} className="mt-6 " {...settings}>
                        {callouts.map(callout => (
                            <div key={callout.name} className="group relative">
                                <div className=" sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1 lg:aspect-h-1 relative h-80 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:h-64">
                                    <img
                                        src={callout.imageSrc}
                                        alt={callout.imageAlt}
                                        className="h-full w-full object-cover object-center"
                                    />
                                    <div className="absolute bottom-0 left-0 flex rounded-tr-lg border bg-white p-3">
                                        <div className="mx-4">
                                            <h1 className="text-base font-semibold text-gray-700 ">
                                                {callout.name}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="mt-6 text-sm text-gray-500">
                                    <a>
                                        <span className="absolute inset-0" />
                                        {callout.name}
                                    </a>
                                </h3>
                                <p className="whitespace-pre-line text-base font-semibold text-gray-900">
                                    {callout.description}
                                </p>
                            </div>
                        ))}
                    </Slider>
                </motion.div>
            </Container>
        </>
    );
};

export default RecrutiCon;
