import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const Container = styled.div<{ imageSrc: string }>(props => [
    `background-image:url("${props.imageSrc}");`,
    props.imageSrc ? tw`relative h-96 bg-cover` : ""
]);
const Row = tw.div`z-20 absolute inset-0 mx-auto max-w-7xl py-28 px-6 sm:px-10 lg:flex lg:items-center lg:justify-between lg:px-8 `;
const CtrBtn = tw.a`inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700`;
const TitleCol = tw.h2`font-bold tracking-tight text-gray-900  whitespace-pre-line `;

const Title = styled.div<{ reverse: boolean }>(props => [
    tw`block font-extrabold text-3xl md:text-4xl`,
    props.reverse ? tw`text-gray-100` : ""
]);

const SubTitle = styled.div<{ reverse: boolean }>(props => [
    tw`block mt-5 text-lg md:text-xl`,
    props.reverse ? tw`text-gray-100` : tw`text-indigo-500`
]);

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-70 h-full`;

const Cta = ({
    title = "세일즈포스에 대해서 더 궁금하신가요?",
    subtitle = "기술과 서비스에 대한 상세문의가 가능합니다",
    button = "문의하기",
    imageSrc = "null",
    reverse = true,
    href = ""
}) => {
    return (
        <Container imageSrc={imageSrc}>
            {reverse === true ? (
                <>
                    <OpacityOverlay />
                </>
            ) : (
                <></>
            )}
            <Row>
                <TitleCol>
                    <Title reverse={reverse}>{title}</Title>
                    <SubTitle reverse={reverse}>{subtitle}</SubTitle>
                </TitleCol>
                <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                    <div className="inline-flex rounded-md shadow">
                        <CtrBtn href={href}>{button}</CtrBtn>
                    </div>
                </div>
            </Row>
        </Container>
    );
};

export default Cta;
