import tw from "twin.macro";

export const Container = tw.div`relative mx-auto max-w-6xl px-6 pb-14 sm:px-6 lg:px-8`;

export const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
export const ContentWithPaddingLg = tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`;
export const ContentWithVerticalPadding = tw.div`py-20 lg:py-24`;
export const Content2Xl = tw.div`max-w-screen-2xl mx-auto`;

export const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`;
export const Subheading = tw.h5`font-bold text-red-800`;

export const PrimaryButton = tw.button`px-8 py-3 font-bold rounded bg-red-500 text-gray-100 hocus:bg-red-700 hocus:text-gray-200  focus:outline-none transition duration-300`;
