import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MenuItem } from "./Datas/MenuItem";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
    DropdownMenu,
    DropDownMenuButton,
    DropdownMenuItems,
    DropdownMenuItem
} from "../layouts/Header";

const Dropdown = () => {
    return (
        <>
            {MenuItem.map((item, index) => {
                return (
                    <Menu as="div" className={DropdownMenu} key={index}>
                        {({ open }) => (
                            <>
                                {item.submenus ? (
                                    <>
                                        {" "}
                                        <Menu.Button
                                            className={DropDownMenuButton}
                                        >
                                            {item.menuname}
                                            <ChevronDownIcon className="ml-1 h-4 w-4 stroke-2" />
                                        </Menu.Button>{" "}
                                        {open && (
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    static
                                                    className={
                                                        DropdownMenuItems
                                                    }
                                                >
                                                    {item.submenus.map(
                                                        (submenu, index) => {
                                                            return (
                                                                <Menu.Item
                                                                    key={index}
                                                                >
                                                                    <a
                                                                        className={
                                                                            DropdownMenuItem
                                                                        }
                                                                        href={
                                                                            submenu.url
                                                                        }
                                                                    >
                                                                        {
                                                                            submenu.submenuname
                                                                        }
                                                                    </a>
                                                                </Menu.Item>
                                                            );
                                                        }
                                                    )}
                                                </Menu.Items>
                                            </Transition>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <a
                                            className={DropDownMenuButton}
                                            href={item.url}
                                        >
                                            {item.menuname}
                                        </a>
                                    </>
                                )}
                            </>
                        )}
                    </Menu>
                );
            })}
        </>
    );
};

export default Dropdown;
