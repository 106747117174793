import React, { useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { motion, useScroll, useSpring } from "framer-motion";
import FeatureHead from "./FeatureHead";
const ProductLists = [
    {
        title: "Sales Cloud",
        description:
            "Sales Cloud로 잠재 고객 발굴부터 거래 성사까지 신속하고 혁신적인 영업을 시작할 수 있습니다.",
        imgSrc: "/img/salesforce/sales-day.svg"
    },
    {
        title: "Service Cloud",
        description:
            "세계 최고의 고객 서비스 소프트웨어를 통해 핵심적인 서비스를 경험해 보세요.",
        imgSrc: "/img/salesforce/service-day.svg"
    },
    {
        title: "Experience cloud",
        description:
            "Experience Cloud를 사용하여 여러 채널에서 고객과 상호 작용하십시오.",
        imgSrc: "/img/salesforce/experience-day.svg"
    },
    {
        title: "Industry Cloud",
        description:
            "세계 1위 CRM 플랫폼에 구축된 산업별 솔루션을 사용하여 어디에서나 디지털 전환을 가속화하고 성공하십시오.",
        imgSrc: "/img/salesforce/industries-day.svg"
    },
    {
        title: "Tableau",
        description:
            "Sales Cloud로 잠재 고객 발굴부터 거래 성사까지 신속하고 혁신적인 영업을 시작할 수 있습니다.",
        imgSrc: "/img/salesforce/tableau-day.svg"
    },
    {
        title: "Heroku",
        description:
            "Sales Cloud로 잠재 고객 발굴부터 거래 성사까지 신속하고 혁신적인 영업을 시작할 수 있습니다.",
        imgSrc: "/img/salesforce/salesforce-heroku.png"
    },
    {
        title: "Customer Identity",
        description:
            "Salesforce 고객 ID에서 단일 로그인으로 사용자 액세스를 간소화합니다.",
        imgSrc: "/img/salesforce/identity.jpeg"
    },
    {
        title: "Customer Data Platform",
        description:
            "스마트 타겟팅, 빠른 세분화, 실시간 자동화로 시간과 비용을 절약하세요.",
        imgSrc: "/img/salesforce/mc-cdp-jt.png"
    },
    {
        title: "Marketing Cloud",
        description:
            "데이터 중심의 디지털 마케팅으로 평생 고객 관계를 구축하세요.",
        imgSrc: "/img/salesforce/marketing-day.svg"
    },
    {
        title: "Loyalty Management",
        description:
            "개인화된 충성도 프로그램으로 고객을 적극적으로 참여시키고 고객이 다시 방문하도록 하십시오.",
        imgSrc: "/img/salesforce/loyalty-01.png"
    },
    {
        title: "CTI Solution",
        description:
            "Salesforce용 CTI를 사용하면 전화 대화 및 통화를 데이터로 만들 수 있습니다.",
        imgSrc: "/img/salesforce/cti.png"
    },
    {
        title: "옴니채널 연동 Solution",
        description:
            "채널톡, 카카오 알림톡, 상담톡, 네이버 톡톡 등과 연동이 가능합니다.",
        imgSrc: "/img/salesforce/omni-ch.png"
    },
    {
        title: "Multi Cloud",
        description:
            "Multi Cloud는 서비스, 상거래 및 마케팅의 세 가지 클라우드 서비스를 결합하여 비즈니스를 발전시킵니다.",
        imgSrc: "/img/salesforce/multicloud.svg"
    }
];
const TabWrap = styled.div.attrs({ id: "tabBusiness" })`
    ${tw`mx-auto z-40 bg-white sticky top-[61px] md:top-[69px] whitespace-nowrap border-b  border-gray-300 w-fit w-full bg-white text-gray-800 overflow-hidden`}
`;

const TabScroll = styled.div`
    ${tw`flex  items-center   justify-start md:justify-center overflow-y-hidden overflow-x-scroll`}
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ProgressBar = styled(motion.div)`
    transform-origin: 0%;
    ${tw`absolute bottom-0  w-full h-1 bg-red-800`}
`;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};

// * source: https://github.dev/Popmotion/popmotion
export const wrap = (min: number, max: number, v: number) => {
    const rangeSize = max - min;
    return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

const FeatureSalesforce = () => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });
    const settings = {
        dots: true,
        autoplay: false,
        Speed: 5000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false
    };

    return (
        <>
            <TabWrap>
                <TabScroll>
                    <motion.a className=" -mb-[1.5px]  px-5 py-4 font-extrabold text-red-800">
                        디지털 트렌스포메이션
                    </motion.a>
                    <motion.a className=" -mb-[1.5px]  px-5 py-4" href="/ICT">
                        ICT융합기술R&D / 사업화
                    </motion.a>
                    <motion.a className=" px-5 py-4" href="/service-incubation">
                        서비스 인큐베이션
                    </motion.a>
                    <ProgressBar style={{ scaleX }} />
                </TabScroll>
            </TabWrap>

            <FeatureHead />
            {/* <div className="container mx-auto max-w-6xl space-y-52 p-6 px-8 py-12 lg:max-w-7xl lg:px-12"></div> */}

            <section className=" bg-gray-50 pb-36" id="1">
                <div className="container mx-auto max-w-6xl  overflow-hidden px-5 py-10 xl:px-0">
                    <p className="mt-4 text-xl font-bold  text-red-800 md:text-2xl">
                        Products
                    </p>
                    <h1 className="font text-3xl  font-bold  tracking-tight text-gray-900 md:text-4xl lg:text-5xl">
                        디지털 트렌스포메이션 제품을 소개합니다.
                    </h1>
                    <div className="mt-8 lg:-mx-12 lg:flex xl:mt-16">
                        <div className="lg:mx-12">
                            <div className="mt-4 space-y-4 lg:mt-8">
                                <a className="block text-gray-500 hover:underline dark:text-gray-300">
                                    전체 상품
                                </a>
                            </div>
                        </div>
                        <div className="mt-8 flex-1 lg:mx-12 lg:mt-0">
                            <div className="mt-8 grid grid-cols-1 gap-8 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 xl:mt-12 xl:gap-12">
                                {ProductLists.map(list => (
                                    <div key={list.title}>
                                        <img
                                            className="h-36 w-full rounded-lg object-contain "
                                            src={list.imgSrc}
                                            alt=""
                                        />
                                        <h2 className="mt-4 text-2xl font-semibold capitalize text-gray-800 dark:text-white">
                                            {list.title}
                                        </h2>
                                        <p className="mt-2 text-sm text-gray-500 md:text-base ">
                                            {list.description}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureSalesforce;
