import React from "react";
import Header from "../components/Header";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Hero from "../components/Heros/HeroContact";
const loading = {
    display:"none",
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    Position: 'fixed',
    background: '#ededed',
    opacity: 0.7,
    zIndex: 99,
    TextAlign: "center",
}

const p_css = {
    fontSize:"x-large",
    fontWeight:"bold",
}

const loading_bar = {
    Position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 100,
    transform : 'translate(-50%, -50%)'
}
const ContactPage = () => (
    <>
        <Hero />
        <div id="loading" style={loading}>
        <div id="loading_bar" style={loading_bar}>
            <img src="/img/spinner.gif"></img>
            <p style={p_css}>로딩 중 입니다 ...</p>
        </div>
        </div>
        <ContactForm />
        <Footer />
    </>
);

export default ContactPage;
