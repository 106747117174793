import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
import { css } from "styled-components/macro";

// 공통 Layout
export const Container = styled.div`
    ${tw`relative w-full bg-center bg-cover `}
`;
export const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-60 h-full`;
export const OverlayImg = styled(motion.div)<{ ImgSrc: any }>`
    ${props =>
        css`
            background: url("${props.ImgSrc}");
        `}
    background-size: 100% 100%;
    transition: background-size 0.3s ease;
    min-height: 100%;
    min-height: -webkit-fill-available;
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        min-height: -webkit-fill-available;
    }
    ${tw`absolute inset-0 h-full w-full bg-cover bg-center`},
`;

export const HeroWrap = styled.div.attrs({ id: "hero" })`
    ${tw`relative w-full bg-cover bg-center overflow-hidden`},
`;
export const HeroWrapMain = tw(HeroWrap)`h-screen`;

export const HeroWrapSub = styled(HeroWrap)`
    height: 30vh;
    ${tw`lg:min-h-[17rem]`}
`;

export const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8 
py-20 lg:py-24 px-5 sm:px-10 lg:px-24`;
export const HeroContent = styled(motion.div)`
    ${tw`z-20 absolute w-full h-full flex flex-col justify-between items-center
py-24 sm:px-8  lg:py-24 px-5 sm:px-10 lg:px-24`}
`;
export const Column = tw.div``;

export const MainWrap = tw.div`z-20 relative text-center lg:text-left
 pt-32 px-10 pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-20 xl:pb-28`;
export const TextColumn = tw(Column)`ml-auto max-w-lg lg:max-w-xl xl:max-w-2xl`;
export const HeroColumn = tw(Column)`m-auto`;
// export const Description = tw.p`mt-3 text-base text-gray-800 sm:mx-auto sm:mt-5 sm:max-w-xl  md:mt-5 md:text-lg lg:mx-0`;
export const ImageColumn = tw(
    Column
)`mx-auto lg:ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;

export const ImageContainer = tw.div`relative mx-auto z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
export const Image = tw.img`max-w-full rounded-2xl h-60 md:h-72 lg:h-80 object-cover relative z-20`;

export const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`;

export const Title = styled.h1`
    ${tw`text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl md:text-5xl`} strong {
        ${tw`relative whitespace-nowrap text-red-800`}
    }
    b {
        ${tw`text-red-200`}
    }
`;

export const Description = styled.div`
    ${tw`text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold text-gray-300`}
    strong {
        ${tw`relative whitespace-nowrap text-red-800`}
    }
    b {
        ${tw` relative whitespace-nowrap text-red-200`}
    }
`;
