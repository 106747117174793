import React from "react";
import Footer from "../components/Footer";
import Cta from "../components/Cta";
import { Variants } from "framer-motion";
import SideLayout from "../components/SideRightLayout";
import Hero from "../components/Heros/HeroRecruit";

const cardVariants: Variants = {
    offscreen: {
        x: -500
    },
    onscreen: {
        x: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
        }
    }
};
const RecruitDetail = () => (
    <>
        <Hero />
        <SideLayout />
        <Footer />
    </>
);

export default RecruitDetail;
