import React, { useState } from "react";
import { Tab } from "@headlessui/react";

import IntroduceOverview from "../IntroduceOverview";
import GridListWithImage from "../GridListwithImage";
import Timeline from "../Timeline";
import CompanyExample from "../CompanyExample";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

const TabBasic = () => {
    const [categories] = useState({
        Overview: [
            {
                id: 1,
                content: (
                    <>
                        <IntroduceOverview /> <CompanyExample />
                        {/* <Features
                            cards={[
                                {
                                    imageSrc: "/img/introduce-01.png",
                                    subtitle: "ICT Convergence Technology",
                                    title: " ICT융합기술 사업화",
                                    description:
                                        "케이유융합소프트웨어연구센터는 ICT융합기술을 지속적으로 연구해왔으며, 현재 ICT융합 기술사업화 및 서비스 인큐베이팅을 통해 4차산업혁명을 선도하고자 합니다.",
                                    url: "#"
                                },
                                {
                                    imageSrc:
                                        "/img/salesforce_partner_badge.png",
                                    subtitle: "Salesforce",
                                    title: "Salesforce Partnership X Digital Transformation",
                                    description:
                                        "KUSRC는 공식 Salesforce Partner로서 Salesforce 도입을 통해서 고객 업무를 혁신하고 궁극적으로 더 많은 가치를 창출할 수 있도록 도와 드립니다.",
                                    url: "/dt"
                                }
                            ]}
                        />
                        <div className="grid grid-cols-2 gap-8 grayscale md:grid-cols-4  lg:grid-cols-5">
                            {ImgSrc.map((img, i) => (
                                <div
                                    key={i}
                                    className=" flex items-center  justify-center "
                                >
                                    <img src={img.imgURL} />
                                </div>
                            ))}
                        </div> */}
                    </>
                )
            }
        ],
        Team: [
            {
                id: 1,
                content: (
                    <>
                        {" "}
                        <GridListWithImage
                            title={[<>Team</>]}
                            description="다양한 산업분야의 기술 R&D 경험을 바탕으로, ICT기반 창의적인 융합서비스 설계 및 수행 역량을 갖춘 유능한 인재들로 구성되어 있습니다."
                            features={[
                                {
                                    title: "",
                                    description:
                                        "전 임직원 Salesforce certifications 보유",
                                    cta: "",
                                    imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
                                },
                                {
                                    title: "",
                                    description:
                                        "Saelsforece Sales/Service Cloud 구축, 연동 웹/모바일 시스템 구축",
                                    cta: "",
                                    imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
                                },
                                {
                                    title: "",
                                    description:
                                        "Saelsforece CRM을 통해 체계적인 고객관리시스템, 실시간 고객과의 소통, 데이터 기반 전략 수립 및 실행이 가능한 환경을 제공",
                                    cta: "",
                                    imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
                                },
                                {
                                    title: "",
                                    description:
                                        "Saelsforece CRM에 반영 가능한 제반기술(특허) 다수 보유",
                                    cta: "",
                                    imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
                                }
                            ]}
                        />
                    </>
                )
            }
        ],
        History: [
            {
                id: 1,
                content: (
                    <>
                        <Timeline />
                    </>
                )
            }
        ]
    });

    return (
        <div id="tab" className="relative mx-auto w-full   pt-10 ">
            <Tab.Group>
                <Tab.List className="sticky  top-[61px] z-30 border-b bg-white sm:top-[69px]">
                    <div className="mx-auto flex w-11/12 space-x-1 rounded-xl md:w-full md:max-w-md">
                        {Object.keys(categories).map(category => (
                            <Tab
                                key={category}
                                className={({ selected }) =>
                                    classNames(
                                        "md:text-md w-full py-3 text-base font-medium leading-5 focus:outline-none md:py-5 md:text-lg ",

                                        selected
                                            ? "border-b-2 border-red-700 bg-white font-extrabold text-red-700"
                                            : "text-gray-700 hover:bg-white/[0.42]  hover:text-red-800"
                                    )
                                }
                            >
                                {category}
                            </Tab>
                        ))}
                    </div>
                </Tab.List>
                <Tab.Panels className="">
                    {Object.values(categories).map((posts, idx) => (
                        <Tab.Panel
                            key={idx}
                            className={classNames("focus:outline-none")}
                        >
                            <>
                                {posts.map(post => (
                                    <div key={post.id} className="relative ">
                                        {post.content}
                                    </div>
                                ))}
                            </>
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};
export default TabBasic;
