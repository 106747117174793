import React from "react";
import { Partners } from "./Datas/Partners";

const CompanyExample = () => {
    const logoImg = { maxHeight: "5rem", maxWidth: "12rem" };
    return (
        <>
            {" "}
            <section className=" bg-gray-50">
                <div className="container mx-auto px-6 py-12">
                    <h1 className="text-2xl font-semibold text-gray-800 dark:text-white lg:text-4xl">
                        기업사례
                    </h1>
                    <div className="mt-8 lg:-mx-12 lg:flex xl:mt-16">
                        <div className="mt-8 flex-1 lg:mx-12 lg:mt-0">
                            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 ">
                                {Partners.map(post => (
                                    <div
                                        key={post.name}
                                        className="flex h-48 flex-col items-center justify-center  rounded-lg bg-white px-6 py-4"
                                    >
                                        <img
                                            style={logoImg}
                                            className="h-full w-full rounded-lg object-contain "
                                            src={post.imageSrc}
                                            alt=""
                                        />
                                        <h2 className="mt-4 text-xl font-semibold capitalize text-gray-800 dark:text-white">
                                            {post.name}
                                        </h2>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CompanyExample;
