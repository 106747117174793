import React from "react";
import Footer from "../components/Footer";
import Hero from "../components/Heros/HeroBusiness";
import Cta from "../components/Cta";
import Feature from "../components/Features/FeatureSalesforce";


const SalesForce = () => (
    <>
        <Hero />
        <Feature />
        <Cta
            title={
                "세일즈포스 도입에 대해 궁금하신 점이 있다면 \n 세일즈포스 전문가와 지금 상의해 보세요."
            }
            subtitle={""}
            href="/contact"
            button="문의하기"
            imageSrc="/img/bg-04.jpg"
        />
        <Footer />
    </>
);

export default SalesForce;
