import * as React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { motion, Variants } from "framer-motion";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Header from "../Header";
import {
    HeroColumn,
    HeroContent,
    HeroWrapMain,
    OpacityOverlay,
    OverlayImg,
    Title,
    Description
} from "../../layouts/Hero";
import UnderlineIcon from "../underline";

const Hero = () => {
    return (
        <>
            <Header />
            <HeroWrapMain>
                <OpacityOverlay />
                <OverlayImg
                    ImgSrc="/img/recruit_img_03.jpg"
                    initial={{ y: 30, opacity: 0, scale: 1 }}
                    animate={{ y: 0, opacity: 1, scale: 1.5 }}
                    transition={{ duration: 4, ease: "easeOut" }}
                />
                <HeroContent
                    initial={{ y: 150, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                >
                    <HeroColumn>
                        <Description>
                            <p className="">
                                <strong>
                                    <UnderlineIcon />
                                    <span>케이유융합소프트웨어연구센터</span>
                                </strong>
                                는
                            </p>
                            <p>
                                <b>ICT융합기술</b>을 기반으로
                            </p>
                            <p>
                                <b>Digital Transformation</b>과
                            </p>
                            <p>
                                <b>융합기술R&D</b>을
                            </p>
                            <p>전문으로 하는 연구소입니다.</p>
                        </Description>
                    </HeroColumn>
                    <motion.a
                        href="#tab"
                        className="flex justify-center "
                        initial={{ y: 0 }}
                        animate={{ y: 0 }}
                        transition={{
                            delay: 0.2,
                            duration: 0.8,
                            repeat: Infinity
                        }}
                    >
                        <ChevronDownIcon className=" h-14 w-14 stroke-slate-50 stroke-2 md:h-20 md:w-20" />
                    </motion.a>
                </HeroContent>
            </HeroWrapMain>
        </>
    );
};

export default Hero;
