import React from "react";
import {
    Container
} from "../layouts/Common";
import tw from "twin.macro";
import styled from "styled-components";

const ContentWithPadding = tw.div`max-w-screen-xl mx-auto`;
const HeadingRow = tw.div`flex`;
const Heading = tw.h2`text-3xl font-black tracking-wide text-center text-gray-900 mb-10`;
const Text = styled.div`
    ${tw`text-base  text-gray-800`}
    p {
        ${tw`mt-2 leading-loose`}
    }
    h1 {
        ${tw`text-2xl font-bold mt-10`}
    }
    h2 {
        ${tw`text-xl font-bold mt-8`}
    }
    h3 {
        ${tw`text-lg font-bold mt-6`}
    }
    ul {
        ${tw`list-decimal list-inside`}
        li {
            ${tw`ml-2 mb-3`}
            p {
                ${tw`mt-0 inline leading-normal`}
            }
        }
    }
`;

const PolicyCon = ({ headingText = "개인정보처리방침" }) => (
    <>
        <Container className="px-5 ">
            <ContentWithPadding>
                <HeadingRow>
                    <Heading>{headingText}</Heading>
                </HeadingRow>
                <Text>
                    <p>[현행] 2022.11.10 시행안</p>
                    <p>
                        케이유융합소프트웨어연구센터(주)(이하 ‘회사’라 한다)은 정보통신망
                        이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등
                        관련 법령(이하 “관련 법령” 이라 함)에 따라 이용자의
                        개인정보를 보호하고, 이와 관련한 고충을 신속하고
                        원활하게 처리할 수 있도록 하기 위하여 다음과 같이
                        개인정보처리방침을 수립합니다. 개인정보처리방침은
                        이용자가 언제나 쉽게 열람할 수 있도록 서비스 초기화면을
                        통해 공개하고 있으며, 개인정보 관련법령, 지침, 고시 또는
                        회사 정책의 변경에 따라 변경될 수 있습니다. 공고 일자 :
                        2022년 11월 10일 시행 일자 : 2022년 11월 10일
                    </p>

                    <h1>세부내용</h1>
                    <h2>1. 개인정보의 수집·이용</h2>
                    <p>
                        회사는 다음과 같이 이용자의 개인정보를 수집합니다.
                        회사가 처리하고 있는 개인정보는 다음의 수집·이용목적
                        이외의 용도로는 활용되지 않으며, 수집·이용목적이
                        변경되는 경우에는 “관련 법령”에 따라 별도의 동의를 받는
                        등 필요한 조치를 이행합니다.
                    </p>
                    <p>
                        이용자 정보의 수집·이용목적, 수집항목, 보유·이용기간은
                        아래와 같습니다.또한 인재영입과 관련된 관계 법령에 따라
                        이용자의 정보를 수집 및 보관할 수 있습니다.
                    </p>
                    <h2>2. 개인정보의 파기절차 및 방법</h2>
                    <ul>
                        <li>
                            <p>
                                회사는 이용자의 개인정보를 원칙적으로
                                보유·이용기간의 경과, 처리목적 달성 등
                                개인정보가 불필요하게 되었을 때에는 지체없이
                                해당 개인정보를 파기합니다.
                            </p>
                        </li>
                        <li>
                            <p>
                                이용자 및 법정대리인의 개인정보 열람, 정정,
                                삭제는 개인정보보호책임자 또는 담당자에게 서면,
                                전화, 전자우편, 모사전송(FAX) 등을 통하여 하실
                                수 있으며 회사는 이에 대해 지체 없이
                                조치하겠습니다.
                            </p>
                        </li>
                    </ul>
                </Text>
            </ContentWithPadding>
        </Container>
    </>
);

export default PolicyCon;
