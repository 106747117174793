import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.scss";
import "./sass/style.scss";
//회사소개
import MainPage from "./pages/MainPage";
import IntroductionPage from "./pages/IntroductionPage";

// 채용
import RecruitTeam from "./pages/RecruitTeamCulture";
import RecruitApply from "./pages/RecruitApply";
import RecruitNotice from "./pages/RecruitNotice";
import RecruitDetail from "./pages/RecruitDetail";

// Business
import SalesForcePage from "./pages/BusinessSalesforce";
import ServiceIncubation from "./pages/BusinessIncubation";
import ICTConvergence from "./pages/BusinessICT";

import CustomerPage from "./pages/CustomerPage";
import ContactPage from "./pages/ContactPage";
import PolicyPage from "./pages/PolicyPage";
import ContactIntro from "./pages/ContactIntro";

function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/main" element={<MainPage />} />
                    <Route path="/about" element={<IntroductionPage />} />

                    <Route path="/customer" element={<CustomerPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/contact-intro" element={<ContactIntro />} />
                    {/* 비즈니스 */}
                    <Route path="/dt" element={<SalesForcePage />} />
                    <Route
                        path="/service-incubation"
                        element={<ServiceIncubation />}
                    />
                    <Route path="/ICT" element={<ICTConvergence />} />

                    {/* 채용 */}
                    <Route path="/recruit" element={<RecruitTeam />} />
                    <Route path="/recruit/jobs" element={<RecruitNotice />} />
                    <Route
                        path="/recruit/jobs/detail/:id" 
                        element={<RecruitDetail />}
                    />
                    <Route path="/recruit/apply" element={<RecruitApply />} />

                    {/* 기타 */}
                    <Route path="/policy" element={<PolicyPage />} />
                    <Route path="/" element={<Navigate replace to="/main" />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
