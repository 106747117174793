import React from "react";
import Footer from "../components/Footer";
import Hero from "../components/Heros/HeroBusiness";
import Features from "../components/Features/FeatureIncubation";

const ServiceIncubation = () => (
    <>
        <Hero />  
        <Features />
        <Footer />
    </>
);

export default ServiceIncubation;
