import React from "react";
import { motion, Variants } from "framer-motion";
import { Container } from "../layouts/Common";
const cardVariants: Variants = {
    offscreen: {
        y: -50
    },
    onscreen: {
        y: 0,
        transition: {
            type: "spring",
            bounce: 0,
            duration: 0.8
        }
    }
};
const callouts = [
    {
        name: "자율적인 근무 환경에서 \n  효율적인 업무가 가능합니다",
        description:
            "9시 ~ 11시 자유로운 출근  \n 자유로운 연차 사용\n근로자의 날 휴무\n  카페테리아 야외 테라스 \n 식대 제공"
    },
    {
        name: "업무와 성장에 \n 몰두할 수 있도록 지원합니다",
        description:
            "식대, 야근교통비 지원 \n  도서, 워크샵, 세미나 등 업무 교육비 100% 지원 \n  최신 Mac & 듀얼모니터 논문\n 논문 무제한 열람비 지원\n자격증 취득 지원\n 신규입사자 교육 프로그램"
    },
    {
        name: "사소한 것까지 \n 회사가 책임집니다",
        description:
            "다양한 간식이 있는 스낵바 \n  직장단체보험 \n  각종 경조사비 지원\n 월 1회 회식비 지원 \n생일 및 결혼기념일 지원\n리조트 회원권 이용 제공"
    }
];

const RecrutiConHead = () => {
    return (
        <>
            <Container>
                <div className="mx-auto max-w-2xl py-16 sm:py-24 md:max-w-3xl lg:max-w-none lg:py-32">
                    <p className="my-2 text-sm text-gray-500 md:text-base">
                        케이유융합소프트웨어연구센터
                    </p>
                    <h1 className="whitespace-pre-line text-3xl font-bold leading-normal text-gray-900 max-[400px]:text-2xl md:text-4xl lg:text-5xl">
                        KUSRC는 일에 더 몰입하고 <br /> 성장할 수 있도록
                        지원하고 있어요.
                    </h1>

                    <div className="mt-6 space-y-12 md:grid md:grid-cols-3 md:gap-x-6 md:space-y-0">
                        {callouts.map(callout => (
                            <div key={callout.name} className="group relative">
                                <h3 className="mt-6 whitespace-pre-line text-lg font-semibold text-gray-900 ">
                                    <span className="absolute inset-0" />

                                    {callout.name}
                                </h3>
                                <p className=" whitespace-pre-line text-sm text-gray-500">
                                    {callout.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </>
    );
};

export default RecrutiConHead;
