import React, { useCallback } from "react";
import { Form, SubmitButton } from "../layouts/Form";
import Input from "./Inputs/InputWithLabel";
import Textarea from "./TextareaWithLabel";
import Modal from "./Modal";
import PolicyCon from "./PolicyCon";
import Select from "./Inputs/Select";
import $ from "jquery";
import axios from "axios";
import useInput from "./@hooks/useInput";

$(document).on("change", "#remember-me", function () {
    if ($("#remember-me").is(":checked")) {
        $("#save_btn").removeAttr("data-attr");
    } else {
        $("#save_btn").attr("data-attr", "disabled");
    }
});

let btn_flag = false;
$(document).on("click", "#save_btn", function () {
    if ($(this).is("[data-attr='disabled']")) {
        alert("개인정보취급방침에 동의해주세요.");
        return false;
    }
});

const ContactForm = ({
    subheading = "Contact Us",
    heading = "문의하기",
    submitButtonText = "문의하기"
}) => {
    const [Name, onChangeName] = useInput("");
    const [content__c, onChangecontent] = useInput("");
    const [phone__c, onChangephone] = useInput("");
    const [email__c, onChangeemail] = useInput("");
    const [company_name__c, onChangecompany_name] = useInput("");

    const onSubmit = useCallback(
        (e: any) => {
            e.preventDefault();
            console.log("전송완료");
            if(btn_flag == false) {
                $("#save_btn").text('문의하기 등록 중..')
                btn_flag = true
                axios
                    .post(
                        "https://kusrc-react-backend.herokuapp.com/v1/contactus/contact-us/",
                        {
                            Name,
                            email__c,
                            phone__c,
                            content__c,
                            company_name__c
                        },
                        { headers: { "Content-Type": "application/json" } }
                    )
                    .then(response => {
                        // 성공시
                        console.log(response);
                        $("#save_btn").text('문의하기')
                        alert("문의가 정상적으로 등록되었습니다.");
                        location.href = "/";
                    })
                    .catch(error => {
                        alert('문의 등록에 실패하였습니다. 다시 한 번 시도해주세요.');
                        $("#save_btn").text('문의하기')
                        btn_flag = false;
                        console.log(error.response);
                    })
                    .finally();
                }else {
                    alert('문의를 등록하고 있습니다.');
                    return false;
                }
        },
        [Name, email__c, phone__c, content__c, company_name__c]
    );
    return (
        <div className="relative mx-auto max-w-2xl px-6 pt-24 pb-44 sm:px-6 lg:px-8 ">
            <h2 className=" text-4xl font-bold leading-none sm:text-5xl">
                {heading}
            </h2>
            <p className=" mt-4 text-sm font-medium uppercase tracking-wider text-gray-700">
                {subheading}
            </p>
            <div className="mt-24 space-y-8">
                <Form onSubmit={onSubmit}>
                    <Input
                        value={company_name__c}
                        onChange={onChangecompany_name}
                        type="text"
                        name="subject"
                        label="회사명"
                    />
                    <Input
                        value={Name}
                        onChange={onChangeName}
                        type="text"
                        name=""
                        label="담당자 이름"
                    />
                    <Input
                        value={email__c}
                        onChange={onChangeemail}
                        type="email"
                        name="email"
                        label="이메일"
                    />
                    <Input
                        value={phone__c}
                        onChange={onChangephone}
                        type="tel"
                        name=""
                        label="연락처"
                    />
                    <Textarea
                        value={content__c}
                        onChange={onChangecontent}
                        name="message"
                        label="문의내용"
                        placeholder="내용을 입력하세요"
                    />
                    <div className="mt-4 flex items-center justify-between">
                        <div className="flex items-center">
                            <input
                                id="remember-me"
                                name="remember-me"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-red-700 focus:ring-red-700"
                            />
                            <label
                                htmlFor="remember-me"
                                className="ml-2 block text-sm text-gray-900"
                            >
                                <Modal
                                    modalname="개인정보취급방침"
                                    modalstyle="text-gray-500 underline  hover:text-gray-300"
                                    dialogTitle=""
                                    dialogContent={<PolicyCon />}
                                    dialogButton="확인"
                                />
                                에 동의합니다.
                            </label>
                        </div>
                    </div>
                    <SubmitButton
                        type="submit"
                        id="save_btn"
                        data-attr="disabled"
                    >
                        {submitButtonText}
                    </SubmitButton>
                </Form>
            </div>
        </div>
    );
};

export default ContactForm;
