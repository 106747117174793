import { useState, useEffect } from "react"

export const useWindowSize = () => {
  const isBrowser = typeof window !== "undefined" // 변경됨
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0) // 변경됨
  const [height, setHeight] = useState(isBrowser ? window.innerHeight : 0) // 변경됨

  useEffect(() => {
    if (!isBrowser) return // 변경됨

    const setWindowSize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }

    window.addEventListener("resize", setWindowSize)

    return () => {
      window.removeEventListener("resize", setWindowSize)
    }
  }, [isBrowser]) // 변경됨

  return {
    width,
    height,
  }
}