import React, { Component, useState } from "react";
import { Container } from "../layouts/Common";

const SimpleTags = ({
    title = "인재상",
    description = "KUSRC와 함께할 인재를 찾고있어요",
    features = [
        {
            title: "High Responsibility",
            description: "책임감을 갖고 있는 사람",
            cta: "",
            imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
        },
        {
            title: "Computational Thinking",
            description: "컴퓨팅 사고력",
            cta: "",
            imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
        },
        {
            title: "Positive Thinking",
            description: "긍정적 사고",
            cta: "",
            imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
        },
        {
            title: "Problem Solving",
            description: "문재해결능력",
            cta: "",
            imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
        },
        {
            title: "Continuous Improvement",
            description: "지속적인 개선",
            cta: "",
            imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
        }
    ]
}) => {
    return (
        <Container>
            <section className="body-font text-gray-600">
                <div className="flex flex-col justify-between">
                    <h1 className="title-font mb-2 text-3xl font-bold text-gray-700 dark:text-white md:text-4xl">
                        {title}
                    </h1>
                    <p className="mb-3 text-base leading-relaxed ">
                        {description}
                    </p>
                </div>
                <div className="-mx-2 flex flex-wrap sm:mx-auto ">
                    {features.map((feature, i) => (
                        <>
                            <div className="w-full p-2 sm:w-1/2 lg:w-1/3 ">
                                <div className="flex h-full items-center rounded bg-gray-100 p-4 transition hover:z-[1] hover:shadow-2xl hover:shadow-inner">
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="3"
                                        className="mr-4 h-6 w-6 flex-shrink-0 text-red-800"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <div>
                                        <div className="title-font font-lg font-semibold">
                                            {feature.title}
                                        </div>
                                        <div className="title-font font-sm text-gray-600">
                                            {feature.description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </section>
        </Container>
    );
};

export default SimpleTags;
