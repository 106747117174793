export const MenuItem = [
    {
        menuname: "회사소개",
        url: "/about"
    },
    {
        menuname: "사업소개",
        submenus: [
            { submenuname: "디지털 트렌스포메이션", url: "/dt" },
            { submenuname: "ICT융합기술R&D 기술사업화", url: "/ICT" },
            { submenuname: "서비스 인큐베이팅", url: "/service-incubation" }
        ]
    },
    {
        menuname: "채용",
        url: "/recruit",
        submenus: [
            { submenuname: "팀 문화", url: "/recruit" },
            { submenuname: "채용공고", url: "/recruit/jobs" }
        ]
    },
    {
        menuname: "Contact Us",
        url: "/contact-intro"
    }
];
