import tw from "twin.macro";
import styled from "styled-components";

export const Container = tw.div`relative`;
export const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto px-12 lg:px-24 py-20 md:py-24`;

export const Row = tw.div`flex flex-col justify-between items-start pt-8 lg:pt-16 max-w-screen-xl mx-auto px-12 lg:px-24`;
export const Column = tw.div`w-full mx-auto  `;
export const HeadColumn = tw(Column)`md:w-3/12 h-auto `;
export const TextColumn = styled(Column)(props => [
    tw`md:w-9/12 mt-16 md:mt-0 rounded-lg ring-blue-500/5  `
]);

export const Image = styled.div(props => [
    tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);

export const TextContent = tw.div`p-8 lg:p-12 text-center md:text-left`;

export const SectionHeading = tw.h2`font-black  tracking-wide`;
export const Subheading = tw(
    SectionHeading
)`text-base text-center text-gray-500 md:text-left`;
export const Heading = tw(
    SectionHeading
)`mt-4 font-black text-red-900 text-left text-3xl sm:text-4xl lg:text-5xl leading-tight`;
export const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-700`;

export const Form = tw.form`text-sm flex flex-col  space-y-8`;
export const Input = tw.input`block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`;

export const Textarea = styled(Input).attrs({ as: "textarea" })`
    ${tw`h-24`}
`;

export const button = tw.button`px-8 py-3 font-bold hover:cursor-pointer rounded bg-red-900 text-gray-100 hocus:bg-red-800 hocus:text-gray-200 focus:outline-none transition duration-300`;
export const SubmitButton = tw(button)`inline-block mt-8`;
