import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";

const sortOptions = [
    { name: "Most Popular", href: "#", current: true },
    { name: "Best Rating", href: "#", current: false },
    { name: "Newest", href: "#", current: false },
    { name: "Price: Low to High", href: "#", current: false },
    { name: "Price: High to Low", href: "#", current: false }
];

import $ from "jquery";

let postion_c;
let enddate_c;
let recruit_cond_c;
let recruit_type_c;
let work_intro_c;


function openPopup(){
    window.open('https://o8vaip94n14.typeform.com/to/ydZbHPt1')
}

$.ajax({
    // URL은 필수 요소이므로 반드시 구현해야 하는 Property입니다.
    url: "https://kusrc-react-backend.herokuapp.com/v1/recruit/recruit-form/", // 요청이 전송될 URL 주소
    type: "GET", // http 요청 방식 (default: ‘GET’)
    dataType: "json", // 응답 데이터 형식 (명시하지 않을 경우 자동으로 추측)
    async: false,
    success: function (data) {
        console.log("1");
    }
}).done(function (data) {
    if (data.records.length > 0) {
        for (let i = 0; i < data.records.length; i++) {
            if (location.href.split("detail/")[1] == data.records[i].Id) {
                postion_c = data.records[i].Name;
                enddate_c = data.records[i].enddate__c;
                recruit_cond_c = data.records[i].recruit_cond__c;
                recruit_type_c = data.records[i].recruit_type__c;
                work_intro_c = String(data.records[i].work_intro__c).replace(/\\r\\n|\\n|\\r/gm,"<br>");
                break;
            }
        }
    }
});

const position = [{ name: postion_c }];

const subCategories = [
    { name: "고용형태", description: recruit_type_c },
    // { name: "직군", description: "Engineering" },
    { name: "모집기간", description: "~" + enddate_c }
];

const posts: any = {
    자격요건: [
        {
            name: "학력",
            description: "학력 : " + recruit_cond_c
        },
        {
            name: "성별",
            description: "성별 : 무관"
        }
    ],
    채용절차: [
        {
            name: "",
            description: "서류전형 → 1차면접 → 2차면접(optional) → 최종합격"
        }
    ],
    모집내용: [
        {
            name: "",
            description: work_intro_c
        }
    ]
};
function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export default function SideLayout() {
    const [contents] = useState(posts);
    // const postKey = Object.keys(posts);

    return (
        <div className="mb-48 bg-white">
            <div>
                <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col items-baseline justify-between border-b border-gray-200 pt-24 pb-6 md:flex-row">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                            {position.map(position => (
                                <>{position.name}</>
                            ))}
                        </h1>

                        <div className=" ml-auto flex items-center">
                            <Menu
                                as="div"
                                className="relative inline-block text-left"
                            ></Menu>

                            {/* <button
                                type="button"
                                className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
                            >
                                <span className="sr-only">View grid</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                                    />
                                </svg>
                            </button>
                            <a
                                href="/recruit/jobs"
                                className="-m-2 ml-5 p-2 text-sm text-gray-400  hover:text-gray-500 sm:ml-7 md:text-base"
                            >
                                목록으로가기
                            </a> */}
                        </div>
                    </div>

                    <section
                        aria-labelledby="products-heading"
                        className="pt-6 pb-24"
                    >
                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                            <div className="lg:col-span-3">
                                <div className="h-full rounded-lg">
                                    {Object.keys(posts).map((post, idx) => (
                                        <>
                                            <h2
                                                className="mb-2 text-lg font-bold tracking-tight text-gray-900"
                                                key={idx}
                                            >
                                                {post}
                                            </h2>
                                            <ul className="mb-16 list-disc space-y-1 pl-7">
                                                {posts[post].map((con: any) => (
                                                    <li
                                                        className="whitespace-pre-line text-gray-800"
                                                        key={con.name}
                                                    >
                                                        {con.description}
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    ))}
                                </div>
                            </div>
                            <form className="order-first block lg:order-last">
                                <h3 className="sr-only">Categories</h3>
                                <ul
                                    role="list"
                                    className="text-[15px] font-medium"
                                >
                                    {subCategories.map(category => (
                                        <li
                                            key={category.name}
                                            className="flex  border-b border-gray-200 py-4"
                                        >
                                            <div className="w-20 text-gray-500">
                                                {category.name}
                                            </div>
                                            <div className="text-red-800">
                                                {category.description}
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                <div className="mt-6 flex flex-col gap-y-2 ">
                                    <div className="fixed bottom-0 left-0 right-0 w-full px-2 pb-2  lg:relative lg:bg-transparent lg:p-0 ">
                                        <div className=" h-7 bg-gradient-to-t from-white to-transparent"></div>
                                            <button type="button" onClick={openPopup} className="hocus:bg-red-800 hocus:text-gray-200  w-full rounded-md bg-red-900 px-8 py-3 font-medium text-gray-100 transition duration-300 focus:outline-none lg:relative">
                                                지원하기
                                            </button>
                                    </div>

                                    {/* <Link to="/recruit">
                                        <button className="hocus:bg-red-800 hocus:text-gray-800 w-full rounded-md bg-red-100 px-8 py-3 font-medium text-red-900 transition duration-300 focus:outline-none">
                                            KUSRC 인재상 보기
                                        </button>
                                    </Link> */}
                                </div>
                            </form>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
}
