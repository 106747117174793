import React from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

const Select = ({
    label = "",
    contents = [
        {
            id: 1,
            name: "개발자",
            avatar: ""
        },
        {
            id: 2,
            name: "Arlene Mccoy",
            avatar: ""
        },
        {
            id: 3,
            name: "Devon Webb",
            avatar: ""
        }
    ],
    value = "",
    onChange = ""
}) => {
    const [selected, setSelected] = useState(contents[0]);

    return (
        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <div>
                    <Listbox.Label className="mb-1 block text-sm font-medium text-gray-700">
                        {label}
                    </Listbox.Label>
                    <div className="relative">
                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-3 pl-3 pr-10 text-left shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm">
                            <span className="flex items-center">
                                {/* <img
                                    src={selected.avatar}
                                    className="h-6 w-6 flex-shrink-0 rounded-full"
                                /> */}
                                <span className="ml-3 block truncate">
                                    {selected.name}
                                </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="w-fulloverflow-auto absolute z-10 mt-1 max-h-56 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {contents.map(content => (
                                    <Listbox.Option
                                        key={content.id}
                                        className={({ active }) =>
                                            classNames(
                                                active
                                                    ? "bg-red-800 text-white"
                                                    : "text-gray-900",
                                                "relative cursor-default select-none py-3 pl-3 pr-9"
                                            )
                                        }
                                        value={content}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                    {/* <img
                                                        src={content.avatar}
                                                        alt=""
                                                        className="h-6 w-6 flex-shrink-0 rounded-full"
                                                    /> */}
                                                    <span
                                                      
                                                        className={classNames(
                                                            selected
                                                                ? "font-semibold"
                                                                : "font-normal",
                                                            "ml-3 block truncate"
                                                        )}
                                                    >
                                                        {content.name}
                                                    </span>
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active
                                                                ? "text-white"
                                                                : "text-red-600",
                                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                                        )}
                                                    >
                                                        <CheckIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    );
};

export default Select;
