import React from "react";

const GridListWithImage = ({
    title = [
        <>
            <span className="underline decoration-blue-500">Components</span>
        </>
    ],
    description = "KUSRC와 함께할 인재를 찾고있어요",
    features = [
        {
            title: "High Responsibility",
            description: "책임감을 갖고 있는 사람",
            cta: "",
            imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
        },
        {
            title: "Computational Thinking",
            description: "컴퓨팅 사고력",
            cta: "",
            imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
        },
        {
            title: "Positive Thinking",
            description: "긍정적 사고",
            cta: "",
            imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
        },
        {
            title: "Problem Solving",
            description: "문재해결능력",
            cta: "",
            imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
        },
        {
            title: "Continuous Improvement",
            description: "지속적인 개선",
            cta: "",
            imgUrl: "https://cdn-icons-png.flaticon.com/512/4341/4341139.png"
        }
    ]
}) => {
    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="container mx-auto px-6 py-10">
                <h1 className="text-3xl font-semibold capitalize text-gray-800 dark:text-white lg:text-4xl">
                    {title}
                </h1>

                <p className="mt-4 font-semibold  text-gray-500 dark:text-gray-300 xl:mt-6">
                    {description}
                </p>

                <div className="mt-8 flex flex-col-reverse lg:flex-row lg:items-center xl:mt-12">
                    <div className="grid w-full grid-cols-1 gap-4 lg:w-1/2 xl:gap-8">
                        {features.map((feature, i) => (
                            <div
                                className=" rounded-xl border border-gray-200 px-6 py-4 "
                                key={i}
                            >
                                <h1 className="text-2xl font-semibold capitalize text-gray-700 dark:text-white">
                                    {feature.title}
                                </h1>
                                <p className="font-base text-gray-500 dark:text-gray-300">
                                    {feature.description}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className=" justify-center lg:flex lg:w-1/2 ">
                        <img
                            className="h-[28rem] w-[28rem] flex-shrink-0  object-contain xl:h-[34rem] xl:w-[34rem]"
                            src="/img/introduce-02.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GridListWithImage;
