import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import RecrutiConHead from "../components/RecruitConHead";
import RecrutiCon from "../components/RecruitCon";
import Cta from "../components/Cta";
import { motion, Variants } from "framer-motion";
import SideLayout from "../components/SideRightLayout";
import ApplyForm from "../components/ApplyForm";
import Hero from "../components/Heros/HeroRecruit";
const cardVariants: Variants = {
    offscreen: {
        x: -500
    },
    onscreen: {
        x: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
        }
    }
};
const RecruitApply = () => (
    <>
        <Hero />
        <ApplyForm />
        <Cta
            title={"KUSRC에 \n 합류하세요."}
            subtitle={
                "케이유융합소프트웨어가 만드는 수많은 혁신의 순간들, 당신과 함께 만들고 싶습니다. 지금, KUSRC 에 합류하세요."
            }
            href="/recruit/jobs"
            button="지원하기"
            imageSrc="/img/bg-04.jpg"
        />
        <Footer />
    </>
);

export default RecruitApply;
