import React from "react";
import { Partners } from "./Datas/Partners";

const Tete = () => {
    const mobileTete = { maxHeight: "2.8rem", maxWidth: "8rem" };
    return (
        <>
            <div className="grid grid-cols-2 justify-center justify-items-center gap-x-3 gap-y-12 px-10 sm:grid-cols-3">
                {Partners.map(img => (
                    <img
                        style={mobileTete}
                        className="h-full w-full object-contain grayscale"
                        key={img.imageSrc}
                        src={img.imageSrc}
                    />
                ))}
            </div>
        </>
    );
};

export default Tete;
