import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PolicyCon from "../components/PolicyCon";
import Hero from "../components/Heros/HeroPolicy";

const PolicyPage = ({ headingText = "개인정보처리방침" }) => (
    <>
        <Hero />
        <div className="my-24">
            <PolicyCon />
        </div>
        <Footer />
    </>
);

export default PolicyPage;
