import React, { useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { motion, useScroll, useSpring } from "framer-motion";

const TabWrap = styled.div.attrs({ id: "tabBusiness" })`
    ${tw`mx-auto z-40 bg-white sticky top-[61px] md:top-[69px] whitespace-nowrap border-b  border-gray-300 w-fit w-full bg-white text-gray-800 overflow-hidden`}
`;

const TabScroll = styled.div`
    ${tw`flex  items-center  justify-center overflow-y-hidden overflow-x-scroll`}
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ProgressBar = styled(motion.div)`
    transform-origin: 0%;
    ${tw`absolute bottom-0  w-full h-1 bg-red-800`}
`;

export const wrap = (min: number, max: number, v: number) => {
    const rangeSize = max - min;
    return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

const FeatureB = () => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });
    const settings = {
        dots: true,
        autoplay: false,
        Speed: 5000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false
    };

    return (
        <>
            <TabWrap>
                <TabScroll>
                    <motion.a className=" -mb-[1.5px]  px-5 py-4" href="/dt">
                        디지털 트렌스포메이션
                    </motion.a>
                    <motion.a className=" -mb-[1.5px]  px-5 py-4" href="/ICT">
                        ICT융합기술R&D /사업화
                    </motion.a>
                    <motion.a
                        className=" px-5 py-4 font-extrabold text-red-800"
                        href="/service-incubation"
                    >
                        서비스 인큐베이션
                    </motion.a>
                    <ProgressBar style={{ scaleX }} />
                </TabScroll>
            </TabWrap>

            <section className="bg-gray-50 pb-36">
                <div className="container mx-auto max-w-6xl space-y-52 p-6 px-8 py-12 lg:max-w-7xl lg:px-12">
                    <div
                        className="grid lg:grid-cols-2 lg:items-center lg:gap-8"
                        id="3"
                        ref={ref}
                    >
                        <div>
                            <h3 className="text-2xl font-bold tracking-tight dark:text-gray-50 sm:text-3xl md:text-4xl">
                                서비스 인큐베이션
                            </h3>
                            <p className="mt-3 text-lg dark:text-gray-400"></p>
                            <div className="mt-12 space-y-12">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex h-12 w-12 items-center justify-center rounded-md bg-red-800 text-gray-100">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                className="h-7 w-7"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-bold leading-6 dark:text-gray-50"></h4>
                                        <p className="mt-2 dark:text-gray-400">
                                            창의적인 ICT융합 아이디어를 앱 또는
                                            웹서비스로 현실화하는 서비스
                                            인큐베이팅은 대학생 및 창업 초기
                                            기업을 대상으로 진행하고 있습니다.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex h-12 w-12 items-center justify-center rounded-md bg-red-800 text-gray-100">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                className="h-7 w-7"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-bold leading-6 dark:text-gray-50"></h4>
                                        <p className="mt-2 dark:text-gray-400">
                                            안정적으로 서비스 개발에 전념할 수
                                            있도록 개발실, 인력, 컨설팅을
                                            지원합니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            aria-hidden="true"
                            className="mt-10 overflow-hidden lg:mt-0"
                        >
                            <Slider {...settings}>
                                <img
                                    src="/img/img_sub2-6.png"
                                    alt=""
                                    className="mx-auto "
                                />
                                <img
                                    src="/img/img_sub2-7.png"
                                    alt=""
                                    className="mx-auto "
                                />
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureB;
