import React from "react";

const TextareaWithLabel = ({
    name = "first-name",
    label = "First name",
    placeholder = "placeholder",
    value = "",
    onChange = ""
}:any) => {
    return (
        <div>
            <label
                htmlFor={name}
                className="block text-left text-sm font-medium text-gray-700"
            >
                {label}
            </label>
            <div className="mt-1">
                <textarea
                    id={name}
                    name={name}
                    rows={3}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                    placeholder={placeholder}
                    defaultValue={""}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default TextareaWithLabel;
