import React from "react";
import Header from "../components/Header";
import Tab from "../components/Tabs/TabBasic";
import Footer from "../components/Footer";
import TabSlide from "../components/Tabs/TabSlide";
import { Container } from "../layouts/Common";
import ColumnListandImage from "../components/ColumnListImage";
import SimpleCheck from "../components/SimpleCheck";
import SimpleTags from "../components/SimpleTags";
import GridListWithImage from "../components/GridListwithImage";

import Hero from "../components/Heros/HeroIntroduce";
import IntroduceOverview from "../components/IntroduceOverview";
import CompanyExample from "../components/CompanyExample";
import Cta from "../components/Cta";

const IntroductionPage = () => (
    <>
        <Hero />
        <Tab />

        {/* <ColumnListandImage /> */}

        <Cta 
            title={"KUSRC 와 \n 디지털 트랜스포메이션의 \n여정을 함께하세요."}
            subtitle={
                ""
            }     href="/contact"
            button="문의하기"
            imageSrc="/img/bg-04.jpg"/>
        <Footer />
    </>
);

export default IntroductionPage;
