import * as React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "../HeaderB";
import {
    HeroColumn,
    HeroContent,
    HeroWrapSub,
    OpacityOverlay,
    OverlayImg,
    Title,
    Description
} from "../../layouts/Hero";
import UnderlineIcon from "../underline";

const Hero = () => {
    return (
        <>
            <Header />
            <HeroWrapSub>
                <OpacityOverlay />
                <OverlayImg
                    ImgSrc="/img/team-overview.jpg"
                    initial={{ y: 30, opacity: 0, scale: 1 }}
                    animate={{ y: 0, opacity: 1, scale: 1.5 }}
                    transition={{ duration: 4, ease: "easeOut" }}
                />
                <HeroContent
                    initial={{ y: 150, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                >
                    <HeroColumn>
                        <Description>
                            <p className="">
                                <b>
                                    <UnderlineIcon />
                                    <span>개인정보처리방침</span>
                                </b>
                                
                            </p>
                          
                        </Description>
                    </HeroColumn>
                  
                </HeroContent>
            </HeroWrapSub>
        </>
    );
};

export default Hero;
