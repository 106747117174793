import React from "react";

const FeatureHead = () => {
    return (
        <div className="relative overflow-hidden bg-white">
            <div className="pt-16 pb-80  sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
                <div className="relative mx-auto max-w-7xl px-8 sm:static sm:px-10 lg:px-8">
                    <div className="sm:max-w-lg">
                        <h1 className="font text-3xl  font-bold  tracking-tight text-gray-900 md:text-4xl lg:text-5xl ">
                            디지털 트렌스포메이션
                        </h1>
                        <p className="mt-4 text-xl font-bold  text-red-800 md:text-2xl">
                            영업 관리 시스템CRM, 사용자 경험CX, 디지털
                            마케팅,디지털 커머스
                        </p>
                        <p className="mt-4 text-lg text-gray-500 md:text-xl">
                            &quot;Salesforce는 세계 최고의 고객 관계 관리CRM
                            플랫폼입니다. 판매, 서비스, 마케팅을 비롯한 
                            고객 데이터를 하나의 통합된 플랫폼에서 활용하게끔
                            하며 KUSRC에서 CRM으로 활용하는
                            노하우를 기반으로 고객의 요구 사항을 이해하고 문제를
                            해결하는 솔루션을 제공해드립니다.&quot;
                        </p>
                    </div>
                    <div>
                        <div className="mt-10">
                            <div
                                aria-hidden="true"
                                className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                            >
                                <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                                    <div className="flex items-center space-x-6 lg:space-x-8">
                                        <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                            <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                                                <img
                                                    src="/img/360-overview-hero-desktop.jpeg"
                                                    alt=""
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                <img
                                                    src="/img/sales-overview-products.png"
                                                    alt=""
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                <img
                                                    src="/img/product-service.png"
                                                    alt=""
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                <img
                                                    src="/img/php-rc-card-1-2.png"
                                                    alt=""
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                <img
                                                    src="/img/salesforce/industry-cloud.jpeg"
                                                    alt=""
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                <img
                                                    src="/img/hp-img-learn-salesforce.jpeg"
                                                    alt=""
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                <img
                                                    src="/img/gartner-82521-1268x714.jpeg"
                                                    alt=""
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <a
                                href="#1"
                                className="inline-block rounded-md border border-transparent bg-red-800 py-3 px-8 text-center font-medium text-white hover:bg-red-700"
                            >
                                더 알아보기
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureHead;
