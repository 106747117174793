import React, { useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Header from "../Header";
import { OverlayImg } from "../../layouts/Hero";
import { useWindowSize } from "../@hooks/useWindowSize";

const OverlayImgC = styled(OverlayImg)`
    ${tw`bg-stone-800`}
`;
const HeroColumn = styled.section<{ height: any }>`
    ${props => (props.height ? `height: ${props.height}px;` : "")}
    ${tw`relative flex flex-col justify-end  overflow-hidden bg-neutral-900`}
`;

const Hero = () => {
    const { height } = useWindowSize();

    return (
        <>
            <Header />

            <HeroColumn height={height}>
                <img
                    className="lg:w-1/2 absolute bottom-0 right-0  top-0 h-1/2  w-full object-cover object-center lg:h-full"
                    src="/img/img_sam-02.jpg"
                />

                <motion.div
                    initial={{ y: 150, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    className="lg:w-1/2  mt-0 px-7  pb-5  md:px-12 lg:mb-36 lg:ml-12"
                >
                    <p className="text-5xl font-semibold text-red-500 ">“</p>
                    <h1 className="">
                        <p className=" text-xl text-gray-300 md:text-2xl xl:text-2xl ">
                            ABOUT KUSRC
                        </p>
                        <p className="text-3xl font-bold tracking-tight text-red-800 md:text-4xl lg:text-5xl">
                            케이유융합소프트웨어
                            <br className="xl:hidden" />
                            연구센터
                        </p>
                    </h1>
                    <p className="mt-6 max-w-lg text-gray-300 ">
                        케이유융합소프트웨어연구센터는 <br />
                        ICT융합기술 을 지속적으로 연구해왔으며,
                        <br />
                        현재 ICT융합기술사업화, Digital Transformation 및 <br />
                        서비스 인큐베이팅 을 통해 <br />
                        4차산업혁명을 선도하고자 합니다.
                    </p>
                </motion.div>

                <motion.a href="#tab" className="flex justify-center ">
                    <ChevronDownIcon className=" z-10 h-14 w-14 stroke-stone-200 stroke-2 md:h-20 md:w-20" />
                </motion.a>
            </HeroColumn>
        </>
    );
};

export default Hero;
