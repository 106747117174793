import React, { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { motion, Variants } from "framer-motion";
import {
    CircleStackIcon,
    AcademicCapIcon,
    BuildingOfficeIcon
} from "@heroicons/react/24/outline";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

const tabs: any = {
    "디지털 트렌스포메이션": [
        {
            no: 1,
            eng: "Digital Transformation",
            title: "디지털 트렌스포메이션",
            description:
                "정보화 시대에 맞추어 전통산업의 Digital Transformation을 위해 Cloud 기반의 SaaS(Software as a Service), PaaS(Platform as a Service) 제공",
            imgSrc: "/img/sales-overview-products.png",
            styles: "translate-x-0",
            transform: { transform: "translateX(0)" },
            icon: (
                <CircleStackIcon
                    stroke="currentColor"
                    className="h-6 w-6 stroke-white"
                />
            )
        }
    ],
    "ICT 융합기술": [
        {
            no: 2,
            eng: "ICT Convergence Technology",
            title: "ICT 융합기술 R&D / 기술사업화",
            description:
                "기존산업에 접목할 수 있는 ICT분야의 융합기술 및 시스템을 기획하고 개발 컨설팅하고, 대학원천기술을 상용화할 수 있는 ICT융합 사업을 기획하고 사업화합니다.",
            imgSrc: "/img/slide1.png",
            styles: "-translate-x-[200%]",
            transform: { transform: "translateX(-100%)" },
            icon: (
                <AcademicCapIcon
                    stroke="currentColor"
                    className="h-6 w-6 stroke-white"
                />
            )
        }
    ],
    "서비스 인큐베이션": [
        {
            no: 4,
            eng: "Service Incubation",
            title: "서비스 인큐베이션",
            description: "창의적인 ICT융합사업 아이디어를 인큐베이팅합니다.  ",
            imgSrc: "/img/slide3.png",
            styles: "-translate-x-[300%]",
            transform: { transform: "translateX(-300%)" },
            icon: (
                <BuildingOfficeIcon
                    stroke="currentColor"
                    className="h-6 w-6 stroke-white"
                />
            )
        }
    ]
};
const cardVariants: Variants = {
    offscreen: {
        y: 100
    },
    onscreen: {
        y: 0,
        transition: {
            type: "spring",
            duration: 0.8
        }
    }
};
const TabMain = () => {
    const [categories] = useState(tabs);
    const tabsKeys = Object.keys(tabs);
    const [clickedButton, setClickedButton] = useState(tabs);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const button: HTMLButtonElement = event.currentTarget;
        setClickedButton(button.name);
    };
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        <div
            id="tab"
            className="mx-auto max-w-screen-lg  px-4 py-16 sm:px-6 md:px-8 md:py-32"
        >
            {windowSize.innerWidth >= 768 ? (
                <>
                    <motion.div className="mx-auto">
                        <Tab.Group defaultIndex={0}>
                            <Tab.List className="grid  grid-cols-1 gap-x-8 gap-y-2 sm:grid-cols-3 lg:grid-cols-3 ">
                                {Object.keys(tabs).map(
                                    (tabKey: any, idx: any): any => (
                                        <Tab
                                            onClick={handleClick}
                                            key={idx}
                                            name={idx}
                                            className={({ selected }) =>
                                                classNames(
                                                    "main-tab flex min-h-[240px] flex-col items-start text-left focus:outline-none  ",
                                                    selected
                                                        ? "tab-selected"
                                                        : ""
                                                )
                                            }
                                        >
                                            {tabs[tabKey].map(
                                                (top: any, idx: any): any => (
                                                    <>
                                                        <div
                                                            key={idx}
                                                            className="bg-active ui-not-selected:bg-slate-100 w-9 rounded-lg bg-slate-500"
                                                        >
                                                            <span className=" flex h-9 w-9 items-center justify-center stroke-current p-1">
                                                                {top.icon}
                                                            </span>
                                                        </div>
                                                        <h3 className="active mt-6 text-sm font-medium text-slate-600">
                                                            {top.eng}
                                                        </h3>
                                                        <p className="active font-display mt-2 text-xl font-bold text-slate-900">
                                                            {top.title}
                                                        </p>
                                                        <p className="mt-4 text-sm text-slate-600">
                                                            {top.description}
                                                        </p>
                                                    </>
                                                )
                                            )}
                                        </Tab>
                                    )
                                )}
                            </Tab.List>
                            <Tab.Panels className="relative mt-20  overflow-hidden  rounded-2xl bg-slate-200   px-4 py-16 xl:px-6">
                                <div className="-mx-5 flex">
                                    {Object.keys(tabs).map(
                                        (tabKey: any): any => (
                                            <>
                                                {tabs[tabKey].map(
                                                    (
                                                        post: any,
                                                        idx: any
                                                    ): any => (
                                                        <Tab.Panel
                                                            key={idx}
                                                            className={({
                                                                selected
                                                            }) =>
                                                                classNames(
                                                                    "mainPanel flex px-5 transition duration-500 ease-in-out",
                                                                    selected
                                                                        ? "tab-selected"
                                                                        : " opacity-40",
                                                                    clickedButton ==
                                                                        "0"
                                                                        ? "translate-x-0 "
                                                                        : clickedButton ==
                                                                          "1"
                                                                        ? "-translate-x-[100%]"
                                                                        : clickedButton ==
                                                                          "2"
                                                                        ? "-translate-x-[200%]"
                                                                        : clickedButton ==
                                                                          "3"
                                                                        ? "-translate-x-[300%]"
                                                                        : "nobutton  "
                                                                )
                                                            }
                                                            static
                                                        >
                                                            <div className="w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
                                                                <img
                                                                    src={
                                                                        post.imgSrc
                                                                    }
                                                                    className="m-auto h-full object-cover"
                                                                />
                                                            </div>
                                                        </Tab.Panel>
                                                    )
                                                )}
                                            </>
                                        )
                                    )}
                                </div>
                                <div className="pointer-events-none absolute inset-0 rounded-2xl ring-1 ring-inset ring-slate-900/10"></div>
                            </Tab.Panels>
                        </Tab.Group>
                    </motion.div>
                </>
            ) : (
                <>
                    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
                        {Object.keys(tabs).map((tabKey: any, idx: any): any => (
                            <div key={idx}>
                                {tabs[tabKey].map(
                                    (post: any, idx: any): any => (
                                        <>
                                            <motion.div
                                                className="mx-auto max-w-2xl"
                                                key={idx}
                                                initial="offscreen"
                                                whileInView="onscreen"
                                                viewport={{
                                                    once: true,
                                                    amount: 0.8
                                                }}
                                                variants={cardVariants}
                                            >
                                                <div className="w-9 rounded-lg bg-red-800">
                                                    <span className=" flex h-9 w-9 items-center justify-center">
                                                        {post.icon}
                                                    </span>
                                                </div>
                                                <h3 className="mt-6 text-sm font-medium text-red-800">
                                                    {post.eng}
                                                </h3>
                                                <p className="mt-2 text-xl font-bold text-slate-900">
                                                    {post.title}
                                                </p>
                                                <p className="mt-4 text-sm text-slate-600">
                                                    {post.description}
                                                </p>
                                            </motion.div>
                                            <div className="relative mt-10 pb-10">
                                                <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6"></div>
                                                <div className="relative mx-auto  overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
                                                    <img
                                                        src={post.imgSrc}
                                                        className="w-full"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};
export default TabMain;
function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}
