import React from "react";
import Hero from "../components/Heros/HeroMain";
import Footer from "../components/Footer";
import Cta from "../components/Cta";
import Tab from "../components/Tabs/MainTab";
import ScrollVelocity from "../components/ScrollVelocity";

const MainPage = () => {
    return (
        <>
            <Hero />
            <Tab />
            <ScrollVelocity />
            {/* <Cta title="서비스 도입이 필요하신가요?" reverse={false} /> */}
            <Footer />
        </>
    );
};

export default MainPage;
