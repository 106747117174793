import React, { useState, useEffect, useRef, useCallback } from "react";
const ImgSrc = [
    {
        //24
        name: "CUROCELL",
        description: "",
        imageSrc: "/img/partners/curocell.png"
    },
    {
        //23
        name: "K2",
        description: "",
        imageSrc: "/img/partners/logo-k2.png"
    },
    {
        //22
        name: "EIDER",
        description: "",
        imageSrc: "/img/partners/logo-eider.png"
    },
    {
        //21
        name: "WIDEANGLE",
        description: "",
        imageSrc: "/img/partners/logo-wangle.png"
    },
    {
        //20
        name: "DYNAFIT",
        description: "",
        imageSrc: "/img/partners/logo-dynafit.png"
    },
    {
        //19
        name: "PIRETTI",
        description: "",
        imageSrc: "/img/partners/logo-piretti.png"
    },
    {
        //18
        name: "NORDISK",
        description: "",
        imageSrc: "/img/partners/logo-nordisk.png"
    },
    {
        //17
        name: "K2SAFETY",
        description: "",
        imageSrc: "/img/partners/logo-k2safety.png"
    },
    {
        //16
        name: "EIDERSAFETY",
        description: "",
        imageSrc: "/img/partners/logo-EIDERSafety.png"
    },
    {
        //15
        name: "디아지오",
        description: "",
        imageSrc: "/img/partners/diageo_logo.svg"
    },
    {
        //14
        name: "커버낫",
        description: "",
        imageSrc: "/img/partners/covernat.png"
    },
    {
        //13
        name: "LEE",
        description: "",
        imageSrc: "/img/partners/lee.png"
    },
    {
        //12
        name: "와릿리즌",
        description: "",
        imageSrc: "/img/partners/whatisisnt.png"
    },
    {
        //11
        name: "이벳필드",
        description: "",
        imageSrc: "/img/partners/ebbet-field.png"
    },
    {
        //10
        name: "팔렛",
        description: "",
        imageSrc: "/img/partners/fallett.png"
    },
    {
        //09
        name: "스피드랙",
        description: "",
        imageSrc: "/img/partners/speedrack_logo.png"
    },
    {
        //08
        name: "브라이언임팩트",
        description: "",
        imageSrc: "/img/partners/logo_brian.svg"
    },
    {
        //07
        name: "방픽파트너스",
        description: "",
        imageSrc: "/img/partners/bangpick.svg"
    },
    {
        //06
        name: "피벗포인트",
        description: "",
        imageSrc: "/img/partners/pivotpoint.png"
    },
    {
        //05
        name: "몬스터짐",
        description: "",
        imageSrc: "/img/partners/monsterzym.png"
    },
    {
        //04
        name: "크로스앵글",
        description: "",
        imageSrc: "/img/partners/crossangle.png"
    },
    {
        //03
        name: "라이트브라더스",
        description: "",
        imageSrc: "/img/partners/wrightbrothers.svg"
    },
    {
        //02
        name: "제이노블",
        description: "",
        imageSrc: "/img/partners/jnoble.png"
    },
    {
        //01
        name: "한경기획",
        description: "",
        imageSrc: "/img/partners/hangkyung-2.png"
    },

];
const ImgSrc2 = [
    {
        //23
        name: "CUROCELL",
        description: "",
        imageSrc: "/img/partners/curocell.png"
    },
    {
        //22
        name: "한국에너지공과대학",
        description: "",
        imageSrc: "/img/partners/kentech.png"
    },
    {
        //21
        name: "삼성드림이비인후과",
        description: "",
        imageSrc: "/img/partners/samsung-dream.png"
    },
    {
        //20
        name: "골프존카운티",
        description: "",
        imageSrc: "/img/partners/golfzone.png"
    },
    {
        //19
        name: "위버스컴퍼니",
        description: "",
        imageSrc: "/img/partners/weverse.png"
    },
    {
        //18
        name: "굿닥",
        description: "",
        imageSrc: "/img/partners/goodoc_img.png"
    },
    {
        //17
        name: "메데어코리아",
        description: "",
        imageSrc: "/img/partners/medair_korea.png"
    },
    {
        //16
        name: "랭글러",
        description: "",
        imageSrc: "/img/partners/wrangler.png"
    },
    {
        //15
        name: "네이머클로딩",
        description: "",
        imageSrc: "/img/partners/namer-clothing.png"
    },
    {
        //14
        name: "스티브알란",
        description: "",
        imageSrc: "/img/partners/steven-alan.png"
    },
    {
        //13
        name: "토니호크",
        description: "",
        imageSrc: "/img/partners/tony-hawk.png"
    },
    {
        //12
        name: "트레셔",
        description: "",
        imageSrc: "/img/partners/thrasher.png"
    },
    {
        //11
        name: "코젠바이오텍",
        description: "",
        imageSrc: "/img/partners/kogen_logo.png"
    },
    {
        //10
        name: "신영와코루",
        description: "",
        imageSrc: "/img/partners/venus_logo.svg"
    },
    {
        //09
        name: "하임랩",
        description: "",
        imageSrc: "/img/partners/heimlab.svg"
    },
    {
        //08
        name: "쥬씨",
        description: "",
        imageSrc: "/img/partners/juicy.png"
    },
    {
        //07
        name: "(주)엔포유기술지주",
        description: "",
        imageSrc: "/img/partners/n4u.png"
    },
    {
        //06
        name: "대교 CNS",
        description: "",
        imageSrc: "/img/partners/dkcns_logo.png"
    },
    {
        //05
        name: "고려대학교 산학협력단",
        description: "",
        imageSrc: "/img/partners/ku_re.jpeg"
    },

    {
        //04
        name: "고려대학교 기술사업화센터",
        description: "",
        imageSrc: "/img/partners/kutlo.png"
    },
    {
        //03
        name: "굿피플",
        description: "",
        imageSrc: "/img/partners/good-people.png"
    },
    {
        //02
        name: "크레이버",
        description: "",
        imageSrc: "/img/partners/craver.png"
    },
    {
        //01
        name: "고려대학교 교육혁신센터",
        description: "Salesforce for education",
        imageSrc: "/img/partners/kuchive_logo.png"
    }
];

const InfiniteLooper = function InfiniteLooper({
    speed,
    direction,
    children
}: {
    speed: any;
    direction: "right" | "left";
    children: React.ReactNode;
}) {
    const [looperInstances, setLooperInstances] = useState(1);
    const outerRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);

    function resetAnimation() {
        if (innerRef?.current) {
            innerRef.current.setAttribute("data-animate", "false");

            setTimeout(() => {
                if (innerRef?.current) {
                    innerRef.current.setAttribute("data-animate", "true");
                }
            }, 10);
        }
    }

    const setupInstances = useCallback(() => {
        if (!innerRef?.current || !outerRef?.current) return;

        const { width } = innerRef.current.getBoundingClientRect();

        const { width: parentWidth } = outerRef.current.getBoundingClientRect();

        const widthDeficit = parentWidth - width;

        const instanceWidth = width / innerRef.current.children.length;

        if (widthDeficit) {
            setLooperInstances(
                looperInstances + Math.ceil(widthDeficit / instanceWidth) + 1
            );
        }

        resetAnimation();
    }, [looperInstances]);

    /*
    6 instances, 200 each = 1200
    parent = 1700
  */

    useEffect(() => setupInstances(), [setupInstances]);

    useEffect(() => {
        window.addEventListener("resize", setupInstances);

        return () => {
            window.removeEventListener("resize", setupInstances);
        };
    }, [looperInstances, setupInstances]);

    return (
        <div
            className="looper flex flex-row items-center justify-center gap-x-8 xl:gap-x-12"
            ref={outerRef}
        >
            <div
                className="looper__innerList"
                ref={innerRef}
                data-animate="true"
            >
                {[...Array(looperInstances)].map((_, ind) => (
                    <div
                        key={ind}
                        className="looper__listInstance"
                        style={{
                            animationDuration: `${speed}s`,
                            animationDirection:
                                direction === "right" ? "reverse" : "normal"
                        }}
                    >
                        {children}
                    </div>
                ))}
            </div>
        </div>
    );
};

const Tete = () => {
    const Tete = { maxHeight: "2.8rem", maxWidth: "8rem" };
    return (
        <>
            <InfiniteLooper speed="40" direction="right">
                <>
                    {ImgSrc.map(img => (
                        <div className="contentBlock " key={img.imageSrc}>
                            <img
                                style={Tete}
                                className="h-full w-full object-contain grayscale"
                                src={img.imageSrc}
                            />
                        </div>
                    ))}
                </>
            </InfiniteLooper>
            <InfiniteLooper speed="40" direction="left">
                <>
                    {ImgSrc2.map(img => (
                        <div className="contentBlock" key={img.imageSrc}>
                            <img
                                style={Tete}
                                className="h-full w-full object-contain grayscale"
                                src={img.imageSrc}
                            />
                        </div>
                    ))}
                </>
            </InfiniteLooper>
        </>
    );
};

export default Tete;
