import React, { useRef, useState, useEffect, useCallback } from "react";
import Input from "./Inputs/InputWithLabel";
import Select from "./Inputs/Select";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import PolicyCon from "./PolicyCon";
import axios from "axios";
import useInput from "./@hooks/useInput";


const ApplyForm = () => {
    const [Name, onChangeName] = useInput("");
    const [phone__c, onChangephone] = useInput("");
    const [resume__c, onChangeresume] = useInput("");
    const [link__c, onChangelink] = useInput("");
    const [email__c, onChangeemail] = useInput("");
    const [job_group__c, onChangejob_group] = useInput("");
    const [total_career__c, onChangetotal_career] = useInput("");

    const handleInputChange = (event: any) => {
        onChangeresume(event.target.files[0]);
    };
    
    const handleSubmit = (event: any) => {
        event.preventDefault();
        const formData = new FormData();

        formData.append("Name", Name);
        formData.append("phone__c", phone__c);
        formData.append("fiels", resume__c);
        formData.append("link__c", link__c);
        formData.append("email__c", email__c);
        formData.append("job_group__c", job_group__c);
        formData.append("total_career__c", total_career__c);

        axios
            .post(
                "https://kusrc-react-backend.herokuapp.com/v1/recruit/recruit-mng/",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
            .then(response => {
                // 성공시
                console.log(response);
            })
            .catch(error => {
                // 실패시
                console.log(error.response);
            })
            .finally();
    };

    const onSubmit = useCallback(
        (e: any) => {
            e.preventDefault();
            console.log("전송완료");

            axios
                .post(
                    "https://kusrc-react-backend.herokuapp.com/v1/recruit/recruit-mng/",
                    {
                        Name,
                        email__c,
                        phone__c,
                        link__c,
                        resume__c,
                        job_group__c,
                        total_career__c
                    },
                    {
                        headers: { "Content-Type": "multipart/form-data" }
                    }
                )
                .then(response => {
                    // 성공시
                    console.log(response);
                })
                .catch(error => {
                    // 실패시
                    console.log(error.response);
                })
                .finally();
        },
        [
            Name,
            email__c,
            phone__c,
            resume__c,
            link__c,
            job_group__c,
            total_career__c
        ]
    );

    return (
        <form
            onSubmit={onSubmit}
            className="relative mx-auto max-w-2xl px-6 pt-36 pb-44 sm:px-6 lg:px-8 "
            encType="multipart/form-data"
        >
            <h2 className=" text-4xl font-bold leading-none sm:text-5xl">
                지원서 작성하기
            </h2>
            <p className=" mt-4 text-sm font-medium uppercase tracking-wider text-gray-700">
                Frontend Developer
            </p>
            <div className="mt-24 space-y-8">
                <Input value={Name} onChange={onChangeName} label="이름" />
                <Input
                    value={phone__c}
                    onChange={onChangephone}
                    type="tel"
                    name="tel"
                    label="전화번호"
                />
                <Input
                    value={email__c}
                    onChange={onChangeemail}
                    type="email"
                    name="email"
                    label="이메일"
                />

                <Input
                    value={resume__c}
                    onChange={onChangeresume}
                    type="file"
                    label="이력서 및 경력기술서"
                    tag={
                        "* 파일은 최대 50MB까지 업로드하실 수 있습니다. \n * 이력서 및 경력기술서는 자유양식이며, 한 개의 파일로 통합하여 제출해주세요."
                    }
                />
                <Input
                    value={link__c}
                    onChange={onChangelink}
                    label="링크"
                    tag="자신을 드러낼 수 있는 개인 블로그나 노션, Github 링크 등을 자유롭게 입력해주세요."
                />
                <Input
                    value={job_group__c}
                    onChange={onChangejob_group}
                    label="담당 직무명"
                />
                <Input
                    value={total_career__c}
                    onChange={onChangetotal_career}
                    label="경력기간"
                />

                <Select
                    label="경력기간"
                    contents={[
                        { id: 1, name: "1년 미만", avatar: "" },
                        { id: 2, name: "1~2년", avatar: "" },
                        { id: 3, name: "2~3년", avatar: "" },
                        { id: 4, name: "4~5년", avatar: "" },
                        { id: 5, name: "5년~7년", avatar: "" },
                        { id: 6, name: "7년 이상", avatar: "" }
                    ]}
                />
            </div>
            <div className="mt-4 flex items-center justify-between">
                <div className="flex items-center">
                    <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-5 w-5 rounded border-gray-300 text-red-700 focus:ring-red-700"
                    />
                    <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                    >
                        <Modal
                            modalname="개인정보취급방침"
                            modalstyle="text-gray-500 underline  hover:text-gray-300"
                            dialogTitle=""
                            dialogContent={<PolicyCon />}
                            dialogButton="확인"
                        />
                        에 동의합니다.
                    </label>
                </div>
            </div>

            <button
                type="submit"
                className="hocus:bg-red-800 hocus:text-gray-200  mt-24 w-full rounded-md bg-red-900 px-8 py-3 font-medium text-gray-100 transition duration-300 focus:outline-none lg:relative"
            >
                지원하기
            </button>
        </form>
    );
};

export default ApplyForm;
