import React from "react";

const InputWithLabel = ({
    name = "first-name",
    label = "First name",
    type = "text",
    tag = "",
    value = "",
    onChange = ""
}: any) => {
    return (
        <div className="col-span-6 sm:col-span-3">
            <label
                htmlFor={name}
                className="block text-left text-sm font-medium text-gray-700"
            >
                {label}
            </label>
            <input
                type={type}
                name={name}
                id={name}
                onChange={onChange}
                value={value}
                autoComplete="given-name"
                className="mt-1 block w-full rounded-md border-gray-300 py-3 shadow-sm 
                 file:border-0                  
                file:bg-red-800 file:text-sm
                file:font-semibold
                file:text-violet-700 hover:file:bg-violet-100
                focus:border-red-500 focus:ring-red-500
                sm:text-sm"
            />
            <p className="mt-2 whitespace-pre-line text-sm text-gray-500">
                {tag}
            </p>
        </div>
    );
};

export default InputWithLabel;
