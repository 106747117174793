import React, { useState,  useEffect,  Fragment } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Bars3Icon } from "@heroicons/react/24/outline";
import {
    NavContainer,
    NavLogo,
    NavTool,
    Btn,
    SearchBtn,
    MenuBtn,
    NavItemsWrap,
    NavItemsWrapMD,
    NavItemsWrapXS,
    NavItems,
    NavItem
} from "../layouts/Header";
import Dropdown from "./Dropdown";

const Nav = styled.nav.attrs({ id: "nav" })<{ active: any }>`
    ${tw`z-[999] w-full top-0 left-0 right-0 fixed  md:px-4   border-gray-200 `}

    ${props =>
        props.active
            ? tw`bg-transparent text-white`
            : tw`bg-white text-gray-800 border-b`}
`;

const Navborder = tw(Nav)`border-b border-gray-200`;

const Header = () => {
    const [menu, showMenu] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    };
    useEffect(() => {
        window.addEventListener("scroll", updateScroll);
    });

    const [windowSize, setWindowSize] = useState(getWindowSize());
    // const [heroSize, setHeroSize] = useState(getHeroSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());

            // setHeroSize(getHeroSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);
    return (
        <>
            <Nav
                active={
                    !(scrollPosition > innerHeight / 4 || menu) ? true : null
                }
            >
                <NavContainer>
                    <NavLogo href="/">
                        <img
                            src="/img/logo.svg"
                            className="h-8 sm:h-12"
                            alt="company Logo"
                        />
                    </NavLogo>
                    <NavTool>
                        {/* <SearchBtn>
             <MagnifyingGlassIcon className="h-6 w-6" />
         </SearchBtn> */}
                        <MenuBtn onClick={() => showMenu(!menu)}>
                            <Bars3Icon className="h-6 w-6" />
                        </MenuBtn>
                    </NavTool>

                    {windowSize.innerWidth >= 768 ? (
                        <>
                            <NavItemsWrapMD>
                                <NavItems>
                                    <Dropdown />
                                </NavItems>
                            </NavItemsWrapMD>
                        </>
                    ) : (
                        <>
                            {menu === true ? (
                                <NavItemsWrapXS>
                                    <NavItems>
                                        <Dropdown />
                                    </NavItems>
                                </NavItemsWrapXS>
                            ) : null}
                        </>
                    )}
                </NavContainer>
            </Nav>
        </>
    );
};

export default Header;

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}
function getHeroSize() {
    const el = document.getElementById("hero") as HTMLDivElement;
    const elHeight: number = el.scrollHeight;
    return { elHeight };
}
