import React, { useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { motion, useScroll, useSpring } from "framer-motion";

const TabWrap = styled.div.attrs({ id: "tabBusiness" })`
    ${tw`mx-auto z-40 bg-white sticky top-[61px] md:top-[69px] whitespace-nowrap border-b  border-gray-300 w-fit w-full bg-white text-gray-800 overflow-hidden`}
`;

const TabScroll = styled.div`
    ${tw`flex  items-center  justify-center overflow-y-hidden overflow-x-scroll`}
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ProgressBar = styled(motion.div)`
    transform-origin: 0%;
    ${tw`absolute bottom-0  w-full h-1 bg-red-800`}
`;

// * source: https://github.dev/Popmotion/popmotion
export const wrap = (min: number, max: number, v: number) => {
    const rangeSize = max - min;
    return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

const FeatureB = () => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });
    const settings = {
        dots: true,
        autoplay: false,
        Speed: 5000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false
    };

    return (
        <>
            <TabWrap>
                <TabScroll>
                    <motion.a className=" -mb-[1.5px]  px-5 py-4" href="/dt">
                        디지털 트렌스포메이션
                    </motion.a>
                    <motion.a
                        className=" -mb-[1.5px]  px-5 py-4  font-extrabold text-red-800"
                        href="/ICT"
                    >
                        ICT융합기술R&D / 사업화
                    </motion.a>
                    <motion.a className=" px-5 py-4" href="/service-incubation">
                        서비스 인큐베이션
                    </motion.a>
                    <ProgressBar style={{ scaleX }} />
                </TabScroll>
            </TabWrap>

            <section className="bg-gray-50 pb-36">
                <div className="container mx-auto max-w-6xl space-y-52 p-6 px-8 py-12 lg:max-w-7xl lg:px-12">
                    {/* <div>
                        <h2 className="text-center   text-2xl  font-bold tracking-tight text-red-800  sm:text-4xl">
                            ICT융합기술R&D / 기술사업화
                        </h2>
                        <p className="mx-auto mt-4 text-center text-base text-gray-400">
                            &quot; 지속적으로 다양한 산업 분야와 접목될 수 있는
                            ICT기반 융합기술을 R&D하고 있습니다&quot;
                        </p>
                    </div> */}

                    <div
                        className="grid lg:grid-cols-2 lg:items-center lg:gap-8"
                        id="1"
                        ref={ref}
                    >
                        <div>
                            <h3 className="text-2xl font-bold tracking-tight dark:text-gray-50 sm:text-3xl md:text-4xl">
                                ICT융합기술R&D
                            </h3>
                            <p className="mt-3 text-lg dark:text-gray-400"></p>
                            <div className="mt-12 space-y-12">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex h-12 w-12 items-center justify-center rounded-md bg-red-800 text-gray-100">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                className="h-7 w-7"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-bold leading-6 dark:text-gray-50">
                                            다양한 산업 분야
                                        </h4>
                                        <p className="mt-2 dark:text-gray-400">
                                            지속적으로 다양한 산업 분야와 접목될
                                            수 있는 ICT기반 융합기술을 R&D하고
                                            있습니다.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex h-12 w-12 items-center justify-center rounded-md bg-red-800 text-gray-100">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                className="h-7 w-7"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-bold leading-6 dark:text-gray-50">
                                            글로벌 기업
                                        </h4>
                                        <p className="mt-2 dark:text-gray-400">
                                            글로벌 기업들과 60여개의
                                            ICT융합기술R&D 프로젝트를
                                            수행하였습니다.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex h-12 w-12 items-center justify-center rounded-md bg-red-800 text-gray-100">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                className="h-7 w-7"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-bold leading-6 dark:text-gray-50">
                                            온오프라인 융합 시스템 및 서비스의
                                            컨설팅{" "}
                                        </h4>
                                        <p className="mt-2 dark:text-gray-400">
                                            R&D 기술력과 조직력을 기반으로
                                            온오프라인 융합 시스템 및 서비스의
                                            컨설팅 업무를 수행하고 있습니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            aria-hidden="true"
                            className="mt-10 overflow-hidden  rounded-lg lg:mt-0"
                        >
                            <Slider {...settings}>
                                <img
                                    src="/img/img_sub2-1.png"
                                    alt=""
                                    className="mx-auto "
                                />

                                <img
                                    src="/img/img_sub2-3.png"
                                    alt=""
                                    className="mx-auto "
                                />
                            </Slider>
                        </div>
                    </div>
                    <div
                        className="grid lg:grid-cols-2 lg:items-center lg:gap-8"
                        id="2"
                        ref={ref}
                    >
                        <div className="lg:col-start-2">
                            <h3 className="text-2xl font-bold tracking-tight dark:text-gray-50 sm:text-3xl md:text-4xl">
                                ICT 융합기술 사업화
                            </h3>
                            <p className="mt-3 text-lg dark:text-gray-400"></p>
                            <div className="mt-12 space-y-12">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex h-12 w-12 items-center justify-center rounded-md bg-red-800 text-gray-100">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                className="h-7 w-7"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-bold leading-6 dark:text-gray-50">
                                            기술사업화(Technology
                                            Commercialization)
                                        </h4>
                                        <p className="mt-2 dark:text-gray-400">
                                            케이유융합소프트웨어연구센터는
                                            대학원천기술을 상용화할 수 있는
                                            ICT융합 사업을 기획하며, 기술의
                                            발굴, 확보, 이전, 상용화 개발,
                                            마케팅, 투자유치의 전단계 프로세스를
                                            수행합니다. 이를
                                            기술사업화(Technology
                                            Commercialization)라고 합니다.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex h-12 w-12 items-center justify-center rounded-md bg-red-800 text-gray-100">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                className="h-7 w-7"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-bold leading-6 dark:text-gray-50">
                                            기술 이전 사업화 및 인큐베이팅
                                        </h4>
                                        <p className="mt-2 dark:text-gray-400">
                                            케이유융합소프트웨어연구센터는 기존
                                            전통산업과 ICT융합을 통해 부가가치를
                                            창출할 수 있는 사업아이템을
                                            발굴하여, 기술 이전 사업화 및
                                            인큐베이팅을 진행하고 있습니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-10 overflow-hidden rounded-lg lg:col-start-1 lg:row-start-1  lg:mt-0 ">
                            <Slider {...settings}>
                                <img
                                    src="/img/img_sub2-4.png"
                                    alt=""
                                    className="mx-auto "
                                />
                                <img
                                    src="/img/img_sub2-5.png"
                                    alt=""
                                    className="mx-auto "
                                />
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureB;
