import React from "react";
import { Container } from "../layouts/Common";
import tw from "twin.macro";
import styled from "styled-components";

const TimeUl = styled.ul`
    ${tw`mb-44 w-full relative before:absolute`} &:nth-child(1)::before {
        content: "2020's";
        display: block;
        top: -17px;
        left: -230px;
        font: 700 48px/1 "Pretendard";
        color: #000;
        display: block;
    }
    &:nth-child(2)::before {
        content: "2021's";
        display: block;
        top: -17px;
        left: -230px;
        font: 700 48px/1 "Pretendard";
        color: #000;
        display: block;
    }
    &:nth-child(3)::before {
        content: "2022's";
        display: block;
        top: -17px;
        left: -230px;
        font: 700 48px/1 "Pretendard";
        color: #000;
        display: block;
    }
`;

const TimeLi = styled.li`
    font: 400 17px/1 "Pretendard";
    color: #666;
    letter-spacing: -0.025em;
    margin-bottom: 1.58em;
    position: relative;
    padding-left: 109px;
    margin-left: 7px;
    &:nth-child(1)::after {
        content: "";
        width: 55px;
        height: 15px;
        position: absolute;
        top: 0;
        left: -54px;
        display: block;
        background: url("img/history-bullet.svg");
    }
`;
const TimeLi2020 = styled(TimeLi)`
    &:nth-child(1)::before {
        content: "2020. 08";
        position: absolute;
        top: -2px;
        left: 11px;
        font: 700 21px/1 "Pretendard";
        color: #000;
        display: block;
    }
    &:nth-child(1)::after {
        content: "";
        width: 55px;
        height: 15px;
        position: absolute;
        top: 0;
        left: -54px;
        display: block;
        background: url("img/history-bullet.svg");
    }
`;
const TimeLi2021 = styled(TimeLi)`
    &:nth-child(1)::before {
        content: "2021. 03";
        position: absolute;
        top: -2px;
        left: 11px;
        font: 700 21px/1 "Pretendard";
        color: #000;
        display: block;
    }
    &:nth-child(2)::before {
        content: "2021. 10";
        position: absolute;
        top: -2px;
        left: 11px;
        font: 700 21px/1 "Pretendard";
        color: #000;
        display: block;
    }
    &:nth-child(3)::before {
        content: "2021. 12";
        position: absolute;
        top: -2px;
        left: 11px;
        font: 700 21px/1 "Pretendard";
        color: #000;
        display: block;
    }
`;
const TimeLi2022 = styled(TimeLi)`
    &:nth-child(1)::before {
        content: "2022. 04";
        position: absolute;
        top: -2px;
        left: 11px;
        font: 700 21px/1 "Pretendard";
        color: #000;
        display: block;
    }
    &:nth-child(2)::before {
        content: "2022. 05";
        position: absolute;
        top: -2px;
        left: 11px;
        font: 700 21px/1 "Pretendard";
        color: #000;
        display: block;
    }
    &:nth-child(4)::before {
        content: "2022. 11";
        position: absolute;
        top: -2px;
        left: 11px;
        font: 700 21px/1 "Pretendard";
        color: #000;
        display: block;
    }
    &:nth-child(6)::before {
        content: "2022. 12";
        position: absolute;
        top: -2px;
        left: 11px;
        font: 700 21px/1 "Pretendard";
        color: #000;
        display: block;
    }
`;

const Timeline = () => {
    return (
        <Container>
            <div className="relative flex w-full flex-col items-end py-12 ">
                <div className="px-{92px}  w-full border-l sm:w-3/5 ">
                    <TimeUl>
                        <TimeLi2020>
                            케이유융합소프트웨어연구센터㈜ 설립(교원창업인가)
                        </TimeLi2020>
                    </TimeUl>
                    <TimeUl>
                        <TimeLi2021>KU-Grant 프로그램 선정</TimeLi2021>
                        <TimeLi2021>기업부설연구소 개소</TimeLi2021>
                        <TimeLi2021>
                            과학기술정통부 강소특구 특화성장 H-Bridge 선정
                        </TimeLi2021>
                    </TimeUl>
                    <TimeUl>
                        <TimeLi2022>벤처기업인증</TimeLi2022>
                        <TimeLi2022>
                            한국지능정보사회진흥원 인공지능 학습용 데이터
                            구축사업 선정
                        </TimeLi2022>
                        <TimeLi2022>
                            중소벤처기업부 창업성장기술개발사업 선정
                        </TimeLi2022>
                        <TimeLi2022>
                            엔포유대학연합기술지주회사 투자 유치
                        </TimeLi2022>
                        <TimeLi2022>
                            2022년 병역지정업체(전문연구요원) 선정
                        </TimeLi2022>
                        <TimeLi2022>홍릉강소특구 연구소기업 선정</TimeLi2022>
                    </TimeUl>
                </div>
            </div>
        </Container>
    );
};

export default Timeline;
