import React from "react";
import { Link } from "react-router-dom";
import Select from "./Inputs/Select";
import InputSearch from "./Inputs/InputSearch";
import $ from "jquery";

const NoticeLists = [
    {
        Id: "",
        Name: "",
        job_group__c: "",
        recruit_type__c: ""
    }
];

$.ajax({
    // URL은 필수 요소이므로 반드시 구현해야 하는 Property입니다.
    url: "https://kusrc-react-backend.herokuapp.com/v1/recruit/recruit-form/", // 요청이 전송될 URL 주소
    type: "GET", // http 요청 방식 (default: ‘GET’)
    dataType: "json", // 응답 데이터 형식 (명시하지 않을 경우 자동으로 추측)
    async: false,
    success: function (data) {
        console.log("1");
    }
}).done(function (data) {
    if (data.records.length > 0) {
        NoticeLists.splice(0);
        for (let i = 0; i < data.records.length; i++) {
            const record = {
                Id: data.records[i].Id,
                Name: data.records[i].Name,
                job_group__c: data.records[i].job_group__c,
                recruit_type__c: data.records[i].recruit_type__c
            };
            console.log(record);
            NoticeLists.push(record);
        }
    } else {
        NoticeLists.splice(0);
    }
});

const RecruitList = () => {
    return (
        <>
            <section className="mx-auto mb-48 max-w-7xl dark:bg-gray-800 dark:text-gray-100">
                <div className="container mx-auto  flex flex-col justify-center px-4 py-8 pt-16 md:px-8 md:pt-24">
                    <p className="my-2 text-sm text-gray-500 md:text-base">
                        케이유융합소프트웨어연구센터
                    </p>
                    <h2 className="text-3xl font-bold !leading-normal sm:text-4xl">
                        KUSRC와 함께 할<br />
                        멋진 동료를 찾고있어요!
                    </h2>
                    <div className="my-10 space-y-4 text-left">
                        {NoticeLists.map(a => (
                            <>
                                <a
                                    href={"/recruit/jobs/detail/" + a.Id}
                                    key={a.Name}
                                    className="relative flex w-full flex-wrap items-start gap-y-2 rounded-lg  px-4 py-6 hover:bg-gray-100 sm:flex-nowrap  sm:items-center sm:justify-between sm:gap-0"
                                >
                                    <div className="w-full  text-base text-lg font-bold  ">
                                        {a.Name}
                                    </div>
                                    <div className="min-w-[150px] text-sm text-gray-600 sm:text-base md:w-1/4  ">
                                        {a.job_group__c}
                                    </div>
                                    <div className="min-w-[80px] text-sm text-gray-600 sm:text-base md:w-1/6">
                                        {a.recruit_type__c}
                                    </div>

                                    <button className="sm:w-1/8 hocus:bg-red-800 hocus:text-gray-200  ml-auto whitespace-nowrap rounded-md text-sm  font-semibold text-gray-500 underline  underline-offset-4 transition duration-300 focus:outline-none md:bg-slate-200 md:px-5 md:py-2 md:no-underline md:text-gray-800  lg:relative">
                                        지원하기
                                        <span className="md:hidden">&gt;</span>
                                    </button>
                                </a>
                                <hr />
                            </>
                        ))}
                    </div>

                    {/* <div className="mt-20 flex justify-center space-x-1 dark:text-gray-100">
                        <button
                            title="previous"
                            type="button"
                            className="inline-flex h-8 w-8 items-center justify-center rounded-md py-0 "
                        >
                            <svg
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="w-4"
                            >
                                <polyline points="15 18 9 12 15 6"></polyline>
                            </svg>
                        </button>
                        <button
                            type="button"
                            title="Page 1"
                            className="inline-flex h-8 w-8 items-center justify-center rounded  bg-red-800 text-sm font-semibold text-white"
                        >
                            1
                        </button>
                        <button
                            type="button"
                            className="inline-flex h-8 w-8 items-center justify-center rounded text-sm  "
                            title="Page 2"
                        >
                            2
                        </button>
                        <button
                            type="button"
                            className="inline-flex h-8 w-8 items-center justify-center rounded  text-sm dark:border-gray-800 dark:bg-gray-900"
                            title="Page 3"
                        >
                            3
                        </button>
                        <button
                            type="button"
                            className="inline-flex h-8 w-8 items-center justify-center rounded  text-sm "
                            title="Page 4"
                        >
                            4
                        </button>
                        <button
                            title="next"
                            type="button"
                            className="inline-flex h-8 w-8 items-center justify-center rounded-md  py-0 "
                        >
                            <svg
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="w-4"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </button>
                    </div> */}
                </div>
            </section>
        </>
    );
};

export default RecruitList;
