import React from 'react'
import Header from '../components/Header'


const CustomerPage = () => (
    <div className="bg-white">
        <Header />
    </div>
)

export default CustomerPage
