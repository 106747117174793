import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import styled from "styled-components";
import tw from "twin.macro";

const OutlineText = styled.span`
    z-index: -1;

    ${tw`text-gray-200 text-[5.6rem] leading-[0]  md:text-[7rem ] lg:text-[9rem]`}
`;

const SubTitle = styled.p`
    ${tw`relative text-3xl tracking-tighter sm:text-4xl font-semibold !leading-snug md:text-4xl lg:text-5xl`};
`;
const IntroduceOverview = () => {
    return (
        <div className="container mx-auto max-w-6xl px-6 py-10">
            {/* <h1 className=" text-3xl font-semibold capitalize text-gray-800 dark:text-white lg:text-4xl xl:text-5xl">
                About <span className="text-red-800 ">KUSRC</span>
            </h1> */}
            {/* <div className="lg:-mx-6 lg:flex lg:items-center lg:justify-center">
                <img
                    className="h-full w-full object-contain  object-center lg:mx-6 lg:h-[36rem] lg:w-1/2"
                    src="/img/introduce-01.png"
                    alt=""
                />
                <div className=" mt-8 lg:mt-0 lg:w-1/2 lg:px-6">
                     <p className="text-5xl font-semibold text-red-700 ">“</p>
                    <h1 className="text-2xl font-bold leading-normal text-gray-600 dark:text-white  xl:text-4xl">
                        케이유융합소프트웨어연구센터는 <br />
                        <span className="underline decoration-red-500">
                            ICT융합기술
                        </span>
                        을 지속적으로 연구해왔으며, <br />
                        현재
                        <span className="underline decoration-red-500">
                            ICT융합기술사업화, Digital Transformation
                        </span>
                        및 <br />
                        <span className="underline decoration-red-500">
                            서비스 인큐베이팅
                        </span>
                        을 통해 <br />
                        4차산업혁명을 선도하고자 합니다.
                    </h1> 
                </div>
            </div> */}
            <div className="mt-12 flex flex-col text-gray-900">
                <div className="flex items-center justify-center align-middle">
                    <img
                        className="h-28 w-28 object-contain"
                        src="/img/salesforce_partner_badge.png"
                    />
                </div>

                <div className=" mt-5 flex  flex-col items-center justify-center  align-middle tracking-tight">
                    <SubTitle>
                        Salesforce Partnership & &nbsp;
                        <br className="xl:hidden"/>
                        Digital Transformation
                    </SubTitle>
                    <p className="mt-6 text-center text-base ">
                        케이유융합소프트웨어연구센터(KUSRC)는  <br className="lg:hidden"/>공식 Salesforce
                        Partner로서
                        <br />
                        Salesforce 도입을 통해서 고객 업무를
                        <br />
                        혁신하고 궁극적으로 더 많은 가치를
                        <br className="lg:hidden " />
                        &nbsp;창출할 수 있도록 도와 드립니다.
                    </p>

                    <Link
                        to="/dt"
                        className="mt-5  flex w-fit items-center border-b-2 border-transparent text-gray-500 transition duration-300 duration-300 hover:border-red-800 hover:text-red-800"
                    >
                        <ArrowRightIcon className="mr-1 h-5 w-5" /> 더 알아보기
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default IntroduceOverview;
