import React from "react";
import Footer from "../components/Footer";
import RecruitList from "../components/RecruitList";
import Hero from "../components/Heros/HeroRecruit";

const RecruitNotice = () => (
    <>
        <Hero/>
        <RecruitList />    
        <Footer />
    </>
);

export default RecruitNotice;
