import tw from "twin.macro";
import styled from "styled-components";


export const NavContainer = tw.div` md:px-3 md:py-2.5  flex max-w-screen-xl flex-wrap items-center justify-between`;
export const NavLogo = tw.a`flex items-center px-4 py-2.5 md:p-0 `;
export const NavTool = tw.div`flex items-center md:order-2 pr-3 py-2.5 md:p-0`;

export const Btn = tw.button`ml-1 inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100
focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600`;
export const SearchBtn = tw(Btn)`md:inline-flex`;
export const MenuBtn = tw(Btn)`md:hidden`;

export const NavItemsWrap = tw.div`w-full items-center ml-auto justify-between text-sm`;
export const NavItemsWrapXS = tw(NavItemsWrap)`w-full `;
export const NavItemsWrapMD = tw(NavItemsWrap)`order-1 flex w-auto`;
export const NavItems = tw.ul`md:inline-flex mt-4 flex flex-col font-medium text-base md:mt-0 md:flex-row md:space-x-8`;
export const NavItem = tw.a`block border-b border-gray-100 py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-blue-500 md:border-0 md:p-0 md:hover:bg-transparent md:hover:text-blue-600 md:dark:hover:bg-transparent md:dark:hover:text-blue-500`;

// Dropdown
export const DropdownMenu = "relative inline-block text-left";
export const DropDownMenuButton =
    "hocus:border-red-800 duration-3000 inline-flex w-full items-center justify-between md:justify-center rounded-md px-4 py-3 transition hover:bg-opacity-30 hover:text-red-900 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75";
export const DropdownMenuItems =
    "static md:absolute md:right-0 z-50 md:mt-2 flex flex-col md:flex-row  w-full md:w-fit origin-top-right divide-gray-100 md:rounded-full bg-gray-100 md:bg-trasnsparent text-black  md:text-white  md:bg-zinc-800 px-5 py-3 md:shadow-sm md:ring-1 md:ring-black md:ring-opacity-5 focus:outline-none";
export const DropdownMenuItem =
    "px-4 py-2 md:p-0 md:mr-10 whitespace-nowrap last:mr-0";
